/* eslint-disable no-self-assign, import/no-cycle */

/**
 * Personalisation tool
 */
import { enhance } from "@grrr/hansel";
import { ENHANCERS } from "../main";

import { closest, listenOnce, map, request } from "./util";

const createButton = () => {
  const button = document.createElement("a");
  button.setAttribute("href", "/");
  button.classList.add("personalisation-tool__back-button");
  button.innerHTML = "Home";
  return button;
};

const createWrapper = () => {
  const themeWrapper = document.createElement("div");
  themeWrapper.className =
    "js-theme-wrapper personalisation-tool__theme-wrapper";
  return themeWrapper;
};

const toggleBodyClass = (condition) => {
  document.body.classList.toggle("has-overlay", condition);
};

const toggleActiveClass = (link) => {
  link.classList.toggle("is-active");
};

/**
 * Based on HdV's trapFocus()
 * https://hiddedevries.nl/en/blog/2017-01-29-using-javascript-to-trap-focus-in-an-element
 */
const trapFocus = (elm) => {
  const focusableElms = elm.querySelectorAll(
    'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
  );
  const firstFocusableElm = focusableElms[0];
  const lastFocusableElm = focusableElms[focusableElms.length - 1];

  elm.addEventListener("keydown", (e) => {
    const isTabPressed = e.key === "Tab";

    if (!isTabPressed) {
      return;
    }
    if (e.shiftKey) {
      /* shift + tab */ if (document.activeElement === firstFocusableElm) {
        lastFocusableElm.focus();
        e.preventDefault();
      }
    } else if (document.activeElement === lastFocusableElm) {
      firstFocusableElm.focus();
      e.preventDefault();
    }
  });
};

const removeOverlay = (wrapper, themeLink) => {
  toggleBodyClass(false);
  if (themeLink) {
    toggleActiveClass(themeLink);
    themeLink.focus(); // Focus previously clicked link
  }
  const themeHeader = wrapper.querySelector(".js-theme-header");
  themeHeader.classList.add("is-removed");
  listenOnce(themeHeader, "animationend", () => {
    wrapper.parentNode.removeChild(wrapper);
  });
};

const triggerOverlay = (elm, themeLink) => {
  const wrapper = createWrapper();
  const backButton = createButton();
  if (themeLink) {
    toggleActiveClass(themeLink);
  }
  // Add class to header when blob is done transitioning
  const blob = themeLink.querySelector(".js-blob");
  blob.addEventListener("transitionend", () => {
    const themeHeader = document.body.querySelector(".js-theme-header");
    if (themeHeader) {
      themeHeader.classList.add("is-transitioned");
    }

    // Initialize themeHeaderImage enhancer again
    const themeHeaderImage = document.body.querySelector(
      ".js-theme-header-image"
    );
    enhance(themeHeaderImage, ENHANCERS);
  });

  // Add wrapper
  document.body.appendChild(wrapper);
  toggleBodyClass(true);

  // Request theme page and append it to wrapper
  request(`${themeLink.getAttribute("href")}?minimal=1`, "get").then(
    (response) => {
      wrapper.innerHTML = response;
      wrapper.querySelector(".js-theme-header__inner").appendChild(backButton);
      backButton.focus();
      trapFocus(wrapper);
    }
  );

  const closeListener = (e) => {
    e.preventDefault();
    removeOverlay(wrapper, themeLink);
    window.history.pushState(
      { page: "Home", themeLinkId: themeLink.id },
      "Waar zit wat in",
      "/"
    );
  };

  listenOnce(backButton, "click", closeListener);
  wrapper.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      closeListener(e);
    }
  });
};

const prefetchPage = (item) => {
  const themeLink = item.querySelector(".js-theme-link");
  request(`${themeLink.getAttribute("href")}?minimal=1`, "get");
};

const init = (elm) => {
  const items = elm.querySelectorAll("li");
  map(items, (item) => {
    item.addEventListener("mouseover", (e) => prefetchPage(item));
  });

  const openListener = (e) => {
    const themeLink = closest(e.target, ".js-theme-link");
    if (!themeLink) {
      return;
    }
    e.preventDefault();
    window.history.pushState(
      { page: "Theme", themeLinkId: themeLink.id },
      {},
      themeLink.getAttribute("href")
    );
    triggerOverlay(elm, themeLink);
  };
  elm.addEventListener("click", openListener);
  elm.addEventListener("keydown", (e) => {
    if (e.key === "Enter") {
      openListener(e);
    }
  });
};

export const onpopstate = (e) => {
  const personalisationTool = document.querySelector(
    '[data-enhancer="personalisationTool"]'
  );
  if (e.state && e.state.page === "Theme") {
    const themeLink = document.getElementById(e.state.themeLinkId);
    triggerOverlay(personalisationTool, themeLink);
  } else if (e.state && e.state.page === "Home") {
    window.location.href = window.location.href;
  } else if (personalisationTool) {
    const wrapper = document.body.querySelector(".js-theme-wrapper");
    const themeLinks = document.querySelectorAll(".js-theme-link");
    map(themeLinks, (themeLink) => {
      themeLink.classList.remove("is-active");
    });
    removeOverlay(wrapper);
  }
};

export const enhancer = init;
