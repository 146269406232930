import Headroom from "headroom.js";
import { matchesBreakpoint } from "./responsive";

/**
 * Sticky header when page is scrolled upwards
 */
export const enhancer = (elm) => {
  const headerHeight = elm.offsetHeight;
  const headroom = new Headroom(elm, {
    offset: headerHeight,
    classes: {
      // init
      initial: "site-header--is-initialized",
      // when scrolling up
      pinned: "site-header--is-pinned",
      // when scrolling down
      unpinned: "site-header--is-unpinned",
      // when above offset = < headerHeight
      top: "site-header--is-at-top",
      // when below offset = > headerHeight
      notTop: "site-header--is-not-at-top",
    },
  });
  if (matchesBreakpoint("mediumLarge")) {
    headroom.init();
  }
};
