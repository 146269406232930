import {
  CATEGORY_FILTER_WAS_CHANGED,
  observe,
  INDEX_CARDS_WERE_FILTERED,
  RELEVANT_FILTER_WAS_CHANGED,
  SEARCH_QUERY_WAS_CHANGED,
  dispatch,
} from "./observer";
import { getValues, regexFrom, match } from "./util";

export function filterCards(data, filterState) {
  return data.filter((card) => {
    const matchesQuery = filterState.query
      ? getValues(card).some(match(regexFrom(filterState.query)))
      : true;

    const matchesRiskgroup = filterState.riskgroupId
      ? card.riskgroups.includes(parseInt(filterState.riskgroupId, 10))
      : true;

    const matchesCategory = filterState.categoryId
      ? card.categories.includes(parseInt(filterState.categoryId, 10))
      : true;

    return matchesQuery && matchesRiskgroup && matchesCategory;
  });
}

function performFiltering(data, filterState) {
  dispatch(INDEX_CARDS_WERE_FILTERED, {
    results: filterCards(data, filterState),
    ...filterState,
  });
}

/**
 * This module listens to various events related to searching and filtering and
 * will perform the actual filtering of items.
 */
export const enhancer = (element) => {
  const dataSource = element.getAttribute("data-source");
  if (typeof dataSource === "undefined") {
    throw new Error("data-source is a required attribute for livesearch");
  }
  const data = window[dataSource];
  if (typeof data === "undefined") {
    throw new Error(`window[${dataSource}] is undefined.`);
  }

  const filterState = {
    query: "",
    categoryId: null,
    riskgroupId: null,
  };

  observe(SEARCH_QUERY_WAS_CHANGED, (query) => {
    filterState.query = query;
    performFiltering(data, filterState);
  });

  observe(RELEVANT_FILTER_WAS_CHANGED, (chosenFilter) => {
    filterState.riskgroupId = chosenFilter
      ? chosenFilter.getAttribute("data-id")
      : null;
    performFiltering(data, filterState);
  });

  observe(CATEGORY_FILTER_WAS_CHANGED, (chosenFilter) => {
    filterState.categoryId = chosenFilter
      ? chosenFilter.getAttribute("data-id")
      : null;
    performFiltering(data, filterState);
  });
};
