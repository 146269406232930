import { observe, INDEX_CARDS_WERE_FILTERED } from "./observer";

const toggleVisibility =
  (el) =>
  ({ results }) => {
    const firstChars = results.reduce((chars, result) => {
      const indexKey = /[a-z]/i.test(result.name[0])
        ? result.name[0].toUpperCase()
        : "#";
      chars[indexKey] = true;
      return chars;
    }, {});

    const letter = el.getAttribute("data-char");

    el.classList.toggle("is-offscreen", !(letter in firstChars));
  };

export const enhancer = (el) => {
  observe(INDEX_CARDS_WERE_FILTERED, toggleVisibility(el));
};
