import playPause from "./play-pause";
import muteUnmute from "./mute-unmute";
import fullScreen from "./full-screen";
import initialPlay from "./initial-play";
import volumeSlider from "./volume-slider";
import audioDescription from "./audio-description";
import progressIndicator from "./progress-indicator";
import playPauseKeyControl from "./play-pause-key-control";
import videoPlayer, { addVideoToTemplate } from "./video-player";
import subtitles, { addSubtitlesToTemplate } from "./subtitles";
import transcript, { addTranscriptToTemplate } from "./transcript";

/**
 * Accessible video player.
 *
 * This is a Custom Element, therefore, it's a class.
 */
class AccessibleVideoPlayer extends HTMLElement {
  static observedAttributes = ["data-subtitles"];

  // State
  listeners = {};

  hasPlayedOnce = false;

  videoIsPlaying = false;

  cachedListener = null;

  isPlaying = false;

  videoIsMuted = false;

  cachedInterval = null;

  videoType = "default";

  currentVideoSize = 0;

  memorizedFocusElement = null;

  volume = 100;

  cachedKeydownEventListener = null;

  subtitlesPopupIsOpen = false;

  showAudioDescription = false;

  constructor() {
    super();

    const template = document.getElementById(
      "accessible-video-player-template"
    );
    const templateContent = template.content;

    // sets and returns 'this.shadowRoot'
    const shadowRoot = this.attachShadow({ mode: "open" });
    const clone = templateContent.cloneNode(true);

    this.props = this.getData();

    /**
     * Add HTML from specific components to the template before it's added to the shadow root.
     * @TODO: Make this chainable
     */
    const cloneWithTranscript = addTranscriptToTemplate(
      clone,
      this.props.transcriptContent
    );
    const cloneWithSubtitles = addSubtitlesToTemplate(
      cloneWithTranscript,
      this.props.defaultSubtitles
    );
    const cloneWithVideo = addVideoToTemplate(cloneWithSubtitles, {
      audioDescriptionVideoSrc: this.props.audioDescriptionVideoSrc,
      defaultVideoSrc: this.props.defaultVideoSrc,
      posterSrc: this.props.posterSrc,
      title: this.props.title,
    });

    // When all content is added to the template, append it to the shadow root.
    shadowRoot.appendChild(cloneWithVideo);

    // Initialize.
    this.initializeVideoPlayer();
    this.initializeObservers();
  }

  initializeVideoPlayer = () => {
    this.videoElement = this.shadowRoot.querySelector(
      ".accessible-video-player__video"
    );
  };

  getData() {
    const subtitlesData = this.getAttribute("data-default-subtitles");
    const videoDescriptionSubtitlesData = this.getAttribute(
      "data-video-description-subtitles"
    );
    const defaultVideoSrcData = this.getAttribute("data-default-video-src");
    const audioDescriptionVideoSrcData = this.getAttribute(
      "data-audio-description-video-src"
    );

    return {
      defaultSubtitles: JSON.parse(subtitlesData),
      audioDescriptionSubtitles: JSON.parse(videoDescriptionSubtitlesData),
      transcriptContent: this.getAttribute("data-transcript"),
      posterSrc: this.getAttribute("data-poster"),
      defaultVideoSrc: JSON.parse(defaultVideoSrcData),
      audioDescriptionVideoSrc: JSON.parse(audioDescriptionVideoSrcData),
      title: this.getAttribute("data-title"),
    };
  }

  initializeObservers = () => {
    fullScreen(this);
    playPause(this);
    playPauseKeyControl(this);
    initialPlay(this);
    muteUnmute(this);
    progressIndicator(this);
    transcript(this);
    volumeSlider(this);
    videoPlayer(this, this.videoElement);
    subtitles(this, {
      subtitlesForDefaultVideo: this.props.defaultSubtitles,
      subtitlesForAudioDescriptionVideo: this.props.audioDescriptionSubtitles,
    });
    audioDescription(this);
  };

  // Methods that can be called from outside the class
  playVideo = () => {
    this.dispatch("AVP_PLAY");
  };

  pauseVideo = () => {
    this.dispatch("AVP_PAUSE");
  };

  updateTimestampVideo = (timeStamp) => {
    this.dispatch("AVP_UPDATE_VIDEO_PROGRESS", {
      timestamp: Number(timeStamp),
    });
  };

  disableFocusableElements = () => {
    this.dispatch("AVP_DISABLE_FOCUSABLE_ELEMENTS");
  };

  enableFocusableElements = () => {
    this.dispatch("AVP_ENABLE_FOCUSABLE_ELEMENTS");
  };

  // Observers
  dispatch = (eventName, payload) => {
    if (typeof this.listeners[eventName] === "undefined") {
      return;
    }
    this.listeners[eventName].forEach((fn) => {
      fn(payload);
    });
  };

  observe = (eventName, listener) => {
    if (typeof this.listeners[eventName] === "undefined") {
      this.listeners[eventName] = [];
    }
    this.listeners[eventName].push(listener);
  };
}

// Initialize function
export default () => {
  // Check if the browser supports custom elements
  if (!window.customElements) return;

  // Check if the browser supports the video element (could be overkill)
  const supportsVideo = !!document.createElement("video").canPlayType;
  if (!supportsVideo) return;

  // Define the new element
  customElements.define("accessible-video-player", AccessibleVideoPlayer);
};
