import { map } from "./util";

/**
 * Tables, within the content, don't have th-elements
 * due to content editing restrictions.
 *
 * To ensure the markup is valid and semantic, we replace
 * the td-elements with th-elements within the first row.
 */
const sanitizeTables = (el) => {
  // Find the tables within the content
  const tables = el.querySelectorAll("table");
  if (!tables) {
    return;
  }

  map(tables, (table) => {
    // Find the table cells in the first row
    const tableRowHeadings = table.querySelectorAll("tr:first-child td");

    map(tableRowHeadings, (tableRowHeading) => {
      const headingText = tableRowHeading.innerText;

      // Create a th element
      const newHeadingElement = document.createElement("th");

      // Apply the text from the old td element to the new th
      newHeadingElement.innerText = headingText;

      // Replace the old td, with the new th
      tableRowHeading.parentNode.replaceChild(
        newHeadingElement,
        tableRowHeading
      );
    });
  });
};

export const enhancer = (el) => {
  sanitizeTables(el);
};
