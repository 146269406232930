const DATA_ATTR_TARGET = "data-target";
const DATA_ATTR_CLASS = "data-target-class";
const DATA_CALLBACK = "data-callback";
const DATA_CALLBACK_ARGS = "data-callback-args";

const triggerIsValid = (item) =>
  item.getAttribute(DATA_ATTR_TARGET) && item.getAttribute(DATA_ATTR_CLASS);

const parseCallbackArgs = (elm) => {
  const args = elm.getAttribute(DATA_CALLBACK_ARGS);
  return args ? args.split(",") : [];
};

export const handler = (elm, e) => {
  e.preventDefault();
  if (!triggerIsValid(elm)) {
    return;
  }
  const target = document.querySelector(elm.getAttribute(DATA_ATTR_TARGET));

  if (!target) {
    return;
  }

  target.classList.toggle(elm.getAttribute(DATA_ATTR_CLASS));
  if (elm.hasAttribute(DATA_CALLBACK)) {
    const callbackArgs = parseCallbackArgs(elm);
    window[elm.getAttribute(DATA_CALLBACK)](elm, target, ...callbackArgs);
  }
};

const isParent = (assumedParent, elm) => {
  if (!elm.parentNode) {
    return false;
  }
  return elm.parentNode === assumedParent
    ? true
    : isParent(assumedParent, elm.parentNode);
};

/**
 * Use this to make clicks on the document toggle the class.
 * For instance to hide modals when you click next to them.
 * The click handler is immediately removed after execution.
 */
export const toggleOnDocumentClick = (elm, target, activeState) => {
  if (!target.classList.contains(activeState)) {
    return;
  }
  const toggleOnce = (e) => {
    if (isParent(target, e.target)) {
      return;
    }
    handler(elm, e);
    document.removeEventListener("click", toggleOnce);
  };

  // We use a setTimeout hack to force a new stack.
  // Otherwise document.onclick would immediately fire.
  setTimeout(() => {
    document.addEventListener("click", toggleOnce);
  }, 0);
};
