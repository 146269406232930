/**
 * Load Fonts.com link through JS instead of renderblocking @import,
 * and without loading their tracking JS file
 */
export default () => {
  const head = document.querySelector("head");
  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.type = "text/css";
  link.href =
    "https://fast.fonts.net/t/1.css?apiType=css&projectid=80270243-de8b-4c8b-9af6-15939d03bf39";
  link.media = "all";

  head.appendChild(link);
};
