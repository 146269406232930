const FETCH_PARAMS = {
  method: "post",
  cache: "no-cache",
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    "X-OCTOBER-REQUEST-HANDLER": "onSubmit",
    "X-OCTOBER-REQUEST-PARTIALS": "",
  },
};

/**
 * Checks that an element has a non-empty `name`.
 * @param  {Element} element  the element to check
 * @return {Bool}             true if the element is an input, false if not
 */
const isValidElement = (element) => {
  return element.name;
};

/**
 * Checks if an element’s value can be saved (e.g. not an unselected checkbox).
 * @param  {Element} element  the element to check
 * @return {Boolean}          true if the value should be added, false if not
 */
const isValidValue = (element) => {
  return !["checkbox", "radio"].includes(element.type) || element.checked;
};

const isCheckbox = (element) => element.type === "checkbox";

/**
 * Some input names for multiselection needs [] at the end of the name for
 * a normal post request. But with for javascript fetch we need to remove
 * these brackets for the JSON payload.
 */
const removeArrayNotation = (string) => {
  return string.replace("[]", "");
};

/**
 * Retrieves input data from a form and returns it as a JSON object.
 * @param  {HTMLFormControlsCollection} elements  the form elements
 * @return {Object}                               form data as an object literal
 */
const formToJSON = (form) =>
  [].reduce.call(
    form.elements,
    (data, element) => {
      if (isValidElement(element) && isValidValue(element)) {
        /*
         * Some fields allow for more than one value, so we need to check if this
         * is one of those fields and, if so, store the values as an array.
         */
        if (isCheckbox(element)) {
          data[removeArrayNotation(element.name)] = (
            data[removeArrayNotation(element.name)] || []
          ).concat(element.value);
        } else {
          data[element.name] = element.value;
        }
      }
      return data;
    },
    {}
  );

const createAlertElement = (form) => {
  const el = document.createElement("div");
  const alertId = "feedback-alert";
  el.id = alertId;
  el.classList.add("feedback-tool__success");
  el.setAttribute("role", "alert");
  el.setAttribute("aria-hidden", "true");
  form.setAttribute("aria-describedby", alertId);
  form.appendChild(el);
  return el;
};

const FeedbackTool = (container) => {
  const button = container.querySelector('button[type="submit"]');
  const form = container.querySelector("form");
  const alertElement = createAlertElement(form);

  const enableButton = () => {
    button.disabled = false;
  };
  const disableButton = () => {
    button.disabled = true;
  };

  const showAlert = (message, type) => {
    if (type === "error") {
      form.setAttribute("aria-invalid", "true");
      form.setAttribute("aria-describedby", alertElement.id);
    }
    alertElement.setAttribute("aria-hidden", "false");
    alertElement.setAttribute("data-type", type);
    alertElement.textContent = message;
  };

  const hideFormFields = () => {
    const stuffToHide = form.querySelectorAll("fieldset, button");
    [...stuffToHide].map((el) => {
      el.style.display = "none";
      return el;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    disableButton();

    fetch(form.getAttribute("action"), {
      ...FETCH_PARAMS,
      body: JSON.stringify(formToJSON(form)),
    }).then((response) => {
      enableButton();
      return response.json().then((result) => {
        showAlert(result.message, result.success ? "success" : "error");
        if (result.success) {
          hideFormFields();
          form.reset();
        }
      });
    });
  };

  return {
    init() {
      form.addEventListener("submit", handleSubmit);
    },
  };
};

export const enhancer = (container) => {
  FeedbackTool(container).init();
};
