import { map, closest } from "./util";

const getExpandedState = (el) => {
  return el.getAttribute("aria-expanded");
};

const toggleAriaExpandedAttribute = (el, isExpanded = "false") => {
  el.setAttribute(
    "aria-expanded",
    el.getAttribute("aria-expanded") === isExpanded
  );
};

const toggleButtonVisibility = (el, isExpanded) => {
  const buttonList = closest(el, ".js-buttons");
  const hiddenItems = buttonList.querySelectorAll(
    ".js-initially-hidden-button"
  );
  map(hiddenItems, (hiddenItem) => {
    hiddenItem.setAttribute("aria-hidden", isExpanded === "false");
  });
  // Hide custom sorted list when card is expanded
  buttonList
    .querySelector(".js-button-list")
    .setAttribute("aria-hidden", isExpanded !== "false");

  // Show alphabetically sorted list when card is expanded
  buttonList
    .querySelector(".js-button-list-sorted")
    .setAttribute("aria-hidden", isExpanded === "false");
};

const toggleExpandedClass = (el, isExpanded) => {
  closest(el, ".js-card").classList.toggle(
    "card--is-expanded",
    isExpanded === "true"
  );
};

const setButtonLabel = (el, isExpanded) => {
  const buttonLabel = el.querySelector("span");
  buttonLabel.innerText =
    isExpanded === "false"
      ? el.getAttribute("data-button-label-collapsed")
      : el.getAttribute("data-button-label-expanded");
};

const setFocus = (el, isExpanded) => {
  const sortedButtonList = closest(el, ".js-buttons").querySelector(
    ".js-button-list-sorted"
  );
  const firstItem = sortedButtonList.querySelector(".js-button-list-link");
  if (isExpanded === "true") {
    firstItem.focus(); // Set focus on first link after expanding
  } else {
    el.focus(); // Set focus on clicked button again after collapsing
  }
};

const overlayActions = (el) => {
  toggleAriaExpandedAttribute(el);
  toggleExpandedClass(el, getExpandedState(el));
  toggleButtonVisibility(el, getExpandedState(el));
  setFocus(el, getExpandedState(el));
  setButtonLabel(el, getExpandedState(el));
};

export const handler = (el, event) => {
  event.preventDefault();
  overlayActions(el);

  document.addEventListener("keydown", (e) => {
    if (e.key === "Escape" && getExpandedState(el) === "true") {
      overlayActions(el);
    }
  });
};
