/* eslint-disable no-unused-vars, default-case */

import { map, prop } from "./util";
import { Swipe } from "./swipe";

const Slider = (
  sliderContainer,
  slideMask,
  slides,
  navButtons,
  nextSlideButtons,
  sliderIndicators
) => {
  const SLIDE_OFFSET = 100;
  let position = 0;

  /**
   * Determine height of heighest slide & apply to slider
   */
  const setSliderHeight = () => {
    const heights = map(slides, prop("offsetHeight"));
    const maxHeight = Math.max(...heights);

    sliderContainer.style.minHeight = `${maxHeight}px`;
  };

  /**
   * Position slides next to each other
   */
  const setSlidePositions = () => {
    map(slides, (slide, index) => {
      slide.style.left = `${SLIDE_OFFSET * index}%`;
    });
  };

  /**
   * Hide nav buttons when no prev of next slide is available
   */
  const toggleNavButtons = () => {
    const prevButton = sliderContainer.querySelector(".slider__nav-item--prev");
    const nextButton = sliderContainer.querySelector(".slider__nav-item--next");

    const firstIndex = 0;
    const lastIndex = slides.length - 1;

    prevButton.classList.toggle("is-hidden", position === firstIndex);
    nextButton.classList.toggle("is-hidden", position === lastIndex);
  };

  /**
   * Add class to current slide
   */
  const determineCurrentSlide = () => {
    const firstIndex = 0;
    const lastIndex = slides.length - 1;

    map(slides, (slide, index) => {
      slide.classList.toggle("is-first", index === firstIndex);
      slide.classList.toggle("is-active", index === position);
      slide.classList.toggle("is-last", index === lastIndex);
    });
  };

  /**
   * Update position of slidermask on nav click
   */
  const transitionSlides = () => {
    slideMask.style.msTransform = `translate(-${SLIDE_OFFSET * position}%, 0)`;
    slideMask.style.transform = `translate3d(-${
      SLIDE_OFFSET * position
    }%, 0, 0)`;
  };

  /**
   * Create slide indicator & add to DOM
   */
  const createSlideIndicator = () => {
    map(slides, (slide, index) => {
      const sliderIndicator = document.createElement("li");
      sliderIndicator.className = "slider__indicator";
      sliderIndicator.innerHTML = `<span>${index}</span>`;
      sliderIndicators.appendChild(sliderIndicator);
      sliderIndicator.classList.toggle("is-active", index === 0);
    });
  };

  /**
   * Update slide indicator when indicator is active
   */
  const updateSlideIndicator = () => {
    const sliderIndicatorDots = sliderIndicators.querySelectorAll("li");

    map(sliderIndicatorDots, (sliderIndicatorDot, index) => {
      sliderIndicatorDot.classList.toggle("is-active", index === position);
    });
  };

  const slideActions = () => {
    transitionSlides(position);
    updateSlideIndicator(position);
    toggleNavButtons(position, navButtons);
    determineCurrentSlide(position);
  };

  /**
   * Set click listener on all slider nav buttons
   */
  const addClickListener = () => (button) => {
    button.addEventListener("click", (e) => {
      position += parseInt(button.getAttribute("data-direction"), 10);
      slideActions();
    });
  };

  /**
   * Swipe through slides
   */
  const swipeSlides = () => {
    const swipe = new Swipe(slideMask, (e, direction) => {
      e.stopPropagation();

      switch (direction) {
        case "left":
          if (position < slides.length - 1) {
            position += parseInt(1, 10);
            slideActions();
          }
          break;
        case "right":
          if (position > 0) {
            position -= parseInt(1, 10);
            slideActions();
          }
          break;
      }
    });
  };

  const init = () => {
    setSliderHeight();
    setSlidePositions();
    createSlideIndicator();
    toggleNavButtons(position, navButtons);
    swipeSlides();
    determineCurrentSlide();

    map(navButtons, addClickListener(navButtons));
    map(nextSlideButtons, addClickListener(navButtons));
  };
  return { init };
};

export const enhancer = (el) => {
  const slides = el.querySelectorAll(".slider__item");
  const slideMask = el.querySelector(".slider__inner");
  const navButtons = el.querySelectorAll(".slider__nav-item");
  const nextSlideButtons = el.querySelectorAll(".slider__next-slide");
  const sliderIndicators = el.querySelector(".slider__indicators");
  const slider = Slider(
    el,
    slideMask,
    slides,
    navButtons,
    nextSlideButtons,
    sliderIndicators
  );

  window.onload = () => {
    slider.init();
  };
};
