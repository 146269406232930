/**
 * Format time with leading zero.
 */
const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time - minutes * 60);

  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
};

/**
 * Add default data to the progress indicator.
 */
const handleLoadedMetaData =
  ({ progressIndicator, currentTimeIndicator, maxTimeIndicator }) =>
  ({ duration }) => {
    // -1 is used to align the progress bar with end. Because it starts with 0.
    progressIndicator.setAttribute("max", Math.ceil(duration - 1));
    currentTimeIndicator.innerHTML = "0:00";
    maxTimeIndicator.innerHTML = formatTime(duration);
  };

/**
 * Update data of the progress indicator.
 */
const handleProgressUpdate =
  ({ progressIndicator, currentTimeIndicator }) =>
  ({ currentTime }) => {
    progressIndicator.value = currentTime;
    currentTimeIndicator.innerHTML = formatTime(currentTime);

    // Update CSS variable for the progress bar (--progress-indicator-value).
    progressIndicator.style.setProperty(
      "--progress-indicator-value",
      `${(currentTime / progressIndicator.max) * 100}%`
    );
  };

const handleKeyDown =
  ({ playerInstance, stepValue }) =>
  (event) => {
    // Pause the video when space is clicked on the progress bar
    if (event.keyCode === 32) {
      event.preventDefault();
      if (playerInstance.isPlaying) {
        playerInstance.dispatch("AVP_PAUSE");
        playerInstance.isPlaying = false;
      } else {
        playerInstance.dispatch("AVP_PLAY");
        playerInstance.isPlaying = true;
      }
    }

    // Clicked on right arrow.
    if (event.keyCode === 39) {
      playerInstance.dispatch("AVP_FORWARD", { amountOfSeconds: stepValue });
    }

    // Clicked on left arrow.
    if (event.keyCode === 37) {
      playerInstance.dispatch("AVP_REWIND", { amountOfSeconds: stepValue });
    }
  };

/**
 * Change value on release of the progress indicator.
 */
const handleChange =
  ({ playerInstance, progressIndicator }) =>
  (event) => {
    playerInstance.dispatch("AVP_UPDATE_VIDEO_PROGRESS", {
      timestamp: Number(progressIndicator.value),
    });
    playerInstance.dispatch("AVP_UPDATE_PROGRESS_INDICATOR", {
      currentTime: Number(progressIndicator.value),
    });
  };

/**
 * Visually update the progress indicator without dispatching the new time.
 * This is done with the change handler.
 */
const visuallyUpdateProgressIndicator =
  ({ progressIndicator }) =>
  (event) => {
    const currentTime = Number(progressIndicator.value);
    // Update CSS variable for the progress bar (--progress-indicator-value).
    progressIndicator.style.setProperty(
      "--progress-indicator-value",
      `${(currentTime / progressIndicator.max) * 100}%`
    );
  };

/**
 * Play/Pause button
 * @param {HTMLElement} playerInstance
 */
export default (playerInstance) => {
  const progressIndicator = playerInstance.shadowRoot.querySelector(
    ".accessible-video-player__progress-bar"
  );
  const currentTimeIndicator = playerInstance.shadowRoot.querySelector(
    ".accessible-video-player__current-time"
  );
  const maxTimeIndicator = playerInstance.shadowRoot.querySelector(
    ".accessible-video-player__max-time"
  );
  const stepValue = progressIndicator.getAttribute("step");

  progressIndicator.addEventListener(
    "keydown",
    handleKeyDown({ playerInstance, stepValue })
  );
  progressIndicator.addEventListener(
    "change",
    handleChange({ playerInstance, progressIndicator })
  );
  // Handle change for each step.
  progressIndicator.addEventListener(
    "input",
    visuallyUpdateProgressIndicator({ progressIndicator })
  );

  playerInstance.observe(
    "AVP_LOADED_META_DATA",
    handleLoadedMetaData({
      progressIndicator,
      currentTimeIndicator,
      maxTimeIndicator,
    })
  );
  playerInstance.observe(
    "AVP_UPDATE_PROGRESS_INDICATOR",
    handleProgressUpdate({
      progressIndicator,
      currentTimeIndicator,
    })
  );
};
