import { closest, map } from "./util";

/**
 * Get the focusable elements within the given container.
 */
const getFocusableElements = (container) => {
  const focusableElements = Array.from(
    container.querySelectorAll(
      'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
    )
  );
  return focusableElements;
};

/**
 * Set initial negative index through JS,
 * to make sure the no-js version is still operable.
 */
const setInitialTabIndex = (el, container, focusableElements) => {
  map(focusableElements, (focusableElement) => {
    focusableElement.setAttribute("tabindex", "-1");
  });
};

const toggleAttributes = (el, container, focusableElements) => {
  const isExpanded = el.getAttribute("aria-expanded");

  /**
   * Set or remove the tabindex attribute depending on the expanded state.
   */
  map(focusableElements, (focusableElement) => {
    if (isExpanded === "true") {
      focusableElement.removeAttribute("tabindex");
    } else {
      focusableElement.setAttribute("tabindex", "-1");
    }
  });

  /**
   * Set the aria-hidden attribute depending on the expanded state.
   */
  container.setAttribute("aria-hidden", isExpanded === "false");
};

export const enhancer = (el) => {
  const contentContainer = el.querySelector(".js-ingredients-list-content");
  setInitialTabIndex(
    el,
    contentContainer,
    getFocusableElements(contentContainer)
  );
};

export const handler = (el) => {
  const contentContainer = closest(
    el,
    `.js-ingredients-list-section`
  ).querySelector(".js-ingredients-list-content");
  toggleAttributes(
    el,
    contentContainer,
    getFocusableElements(contentContainer)
  );
};
