const getSubstances = (query) => {
  const substancesCategories = window.subtances_categories;
  const substanceCategoriesList = Object.values(substancesCategories);

  return !query
    ? substanceCategoriesList
    : substanceCategoriesList.filter((category) =>
        category.toLowerCase().includes(query.toLowerCase())
      );
};

export const enhancer = (option) => {
  option.addEventListener("keyup", (event) => {
    const foundCategories = getSubstances(event.currentTarget.value);

    const categoryOptions = document.querySelectorAll(
      ".category-filter__option"
    );

    const categoryOptionsList = Array.from(categoryOptions);

    categoryOptionsList.forEach((categoryOption) => {
      if (
        !foundCategories.includes(
          categoryOption.getAttribute("data-filter-name")
        )
      ) {
        categoryOption.classList.add("category-filter__option--hide");
      } else {
        categoryOption.classList.remove("category-filter__option--hide");
      }
    });
  });
};
