import { nextTick } from "./util";

const createHiddenInput = () => {
  const input = document.createElement("input");
  input.style.cssText = "position: absolute; left: -9999px;";
  document.body.appendChild(input);
  return input;
};

/**
 * Stole from: https://stackoverflow.com/a/35385518
 * @param html
 */
const convertHtmlToElement = (html) => {
  const div = document.createElement("div");
  div.innerHTML = html.trim();
  return div.firstChild;
};

const appendPopupToButton = (el) => {
  const html = `
    <div class="button-popup" aria-hidden="true">
      <span class="button-popup__heading">Link gekopieërd naar het klembord</span>
      <span class="button-popup__url"></span>
    </div>
  `;
  const element = convertHtmlToElement(html);
  el.appendChild(element);
  return element;
};

const destroyElement = (el) => {
  el.parentNode.removeChild(el);
};

const copyUrlToClipboard = (href) => {
  const input = createHiddenInput();
  // Set hidden input to current browser url
  input.value = href;
  // Select text in input
  input.select();
  // Copy to clipboard
  document.execCommand("copy");
  // Remove hidden input
  destroyElement(input);
};

const animatePopup = (el, href) => {
  if (el.querySelector(".button-popup")) {
    return;
  }
  const popup = appendPopupToButton(el);
  const urlSpan = popup.querySelector(".button-popup__url");
  urlSpan.innerHTML = href;
  // Animate popup
  popup.addEventListener("transitionend", () => {
    if (window.getComputedStyle(popup).opacity === "0") {
      destroyElement(popup);
    }
  });
  nextTick(() => popup.setAttribute("aria-hidden", false));
  setTimeout(() => popup.setAttribute("aria-hidden", true), 3000);
};

export const handler = (el) => {
  const { href } = window.location;
  copyUrlToClipboard(href);
  animatePopup(el, href);
};
