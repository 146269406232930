/* eslint-disable no-plusplus */

/**
 * Responsive breakpoint registry
 */
let docWidth;
const BREAKPOINT_SMALL = 540;
const BREAKPOINT_MEDIUM = 680;
const BREAKPOINT_MEDIUM_LARGE = 900;
const BREAKPOINT_LARGE = 1200;
const BREAKPOINT_EXTRA_LARGE = 1400;

const setDocWidth = () => {
  docWidth = window.innerWidth;
};

export const getDocWidth = () => {
  if (!docWidth) {
    setDocWidth();
  }
  return docWidth;
};

export const matchesBreakpoint = (breakpoint) => {
  switch (breakpoint) {
    case "small":
      return getDocWidth() >= BREAKPOINT_SMALL;
    case "medium":
      return getDocWidth() >= BREAKPOINT_MEDIUM;
    case "mediumLarge":
      return getDocWidth() >= BREAKPOINT_MEDIUM_LARGE;
    case "large":
      return getDocWidth() >= BREAKPOINT_LARGE;
    case "extraLarge":
      return getDocWidth() >= BREAKPOINT_EXTRA_LARGE;
    default:
      return false;
  }
};

export const getCurrentBreakpoint = () => {
  const tries = ["small", "medium", "mediumLarge", "large", "extraLarge"];
  let i = 0;
  let bp = "small";

  do {
    bp = tries[i];
  } while (matchesBreakpoint(tries[++i]));
  return bp;
};

export const enhancer = () => {
  window.addEventListener("resize", setDocWidth);
  window.addEventListener("orientationchange", setDocWidth);
};
