import { tooltipOnHoverAndFocus } from "./tooltip";

const close =
  ({ playerInstance, transcriptContainer, trigger }) =>
  (event) => {
    transcriptContainer.setAttribute("aria-hidden", "true");
    trigger.setAttribute("aria-expanded", "false");

    playerInstance.memorizedFocusElement.focus();
    playerInstance.memorizedFocusElement = null;
  };

const open =
  ({ playerInstance, transcriptContainer, trigger, focus }) =>
  (event) => {
    playerInstance.memorizedFocusElement =
      playerInstance.shadowRoot.activeElement;

    transcriptContainer.setAttribute("aria-hidden", "false");
    trigger.setAttribute("aria-expanded", "true");

    focus.focus();
  };

/**
 * Transcript button
 * @param {HTMLElement} playerInstance
 */
export default (playerInstance) => {
  const trigger = playerInstance.shadowRoot.querySelector(
    ".accessible-video-player__button--transcribe-button"
  );
  const closeButton = playerInstance.shadowRoot.querySelector(
    ".accessible-video-player__transcript-close"
  );
  const transcriptContainer = playerInstance.shadowRoot.querySelector(
    ".accessible-video-player__transcript"
  );

  trigger.addEventListener(
    "click",
    open({
      playerInstance,
      transcriptContainer,
      trigger,
      focus: closeButton,
    })
  );
  closeButton.addEventListener(
    "click",
    close({ playerInstance, transcriptContainer, trigger })
  );

  const tooltipElement = trigger.querySelector(
    ".accessible-video-player__button-hover-target"
  );
  tooltipOnHoverAndFocus(playerInstance, trigger, tooltipElement, "transcript");
};

/**
 * Add transcription to template based on data.
 *
 * @param {HTMLElement} template
 * @param {Array} trancription
 * @returns {HTMLElement}
 */
export function addTranscriptToTemplate(template, transcript) {
  if (!transcript) {
    const videoContainer = template.querySelector(
      ".accessible-video-player__inner"
    );
    const trancribeButton = videoContainer.querySelector(
      ".accessible-video-player__button--transcribe-button"
    ).parentElement;

    trancribeButton.setAttribute("aria-hidden", true);

    return template;
  }

  const transcriptText = template.querySelector(
    ".accessible-video-player__transcript-text"
  );

  if (transcript) {
    transcriptText.innerHTML = transcript;
  }

  return template;
}
