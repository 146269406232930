import { tooltipOnHoverAndFocus } from "./tooltip";

/**
 * Toggle the audio description on and off.
 */
const handleClick = (playerInstance, triggerLabel) => (event) => {
  event.preventDefault();

  if (playerInstance.showAudioDescription) {
    playerInstance.dispatch("AVP_USE_DEFAULT_VIDEO");
    playerInstance.showAudioDescription = false;
    triggerLabel.textContent = "Video met audiobeschrijving";
  } else {
    playerInstance.dispatch("AVP_USE_AUDIO_DESCRIPTION_VIDEO");
    playerInstance.showAudioDescription = true;
    triggerLabel.textContent = "Video zonder audiobeschrijving";
  }

  // Set the subtitles to off, no matter the state.
  playerInstance.dispatch("AVP_UPDATE_SUBTITLES", { id: "off" });
};

/**
 * Audio description button.
 * @param {HTMLElement} playerInstance
 */
export default (playerInstance) => {
  const trigger = playerInstance.shadowRoot.querySelector("#audio-description");
  const triggerLabel = trigger.querySelector(
    ".accessible-video-player__button-hover-target"
  );

  trigger.addEventListener("click", handleClick(playerInstance, triggerLabel));

  tooltipOnHoverAndFocus(
    playerInstance,
    trigger,
    triggerLabel,
    "audio-description"
  );
};
