import { debounce } from "./util";
import { dispatch, SEARCH_QUERY_WAS_CHANGED } from "./observer";

export const enhancer = (form) => {
  const KEYUP_TIMEOUT = 200;

  form.addEventListener("keyup", function (e) {
    return debounce(function () {
      dispatch(SEARCH_QUERY_WAS_CHANGED, e.target.value);
    }, KEYUP_TIMEOUT)(e);
  });
};
