import { map, nextUntil } from "./util";

const toggleAttributesOnClick = (button) => {
  button.addEventListener("click", (e) => {
    button.setAttribute(
      "aria-expanded",
      button.getAttribute("aria-expanded") === "false"
    );
  });
};

const wrapContentItems = (button) => {
  setTimeout(() => {
    const items = nextUntil(button, ".collapsible-section__button");
    const container = document.createElement("div");
    container.classList.add("collapsible-section__content");
    map(items, (item) => container.appendChild(item));
    button.parentNode.insertBefore(container, button.nextSibling);
  }, 0);
};

const createCollapsibleButton = (heading) => {
  const button = document.createElement("button");
  button.setAttribute("type", "button");
  button.classList.add("collapsible-section__button");
  button.setAttribute("aria-expanded", "false");

  heading.parentNode.insertBefore(button, heading);
  button.appendChild(heading);

  toggleAttributesOnClick(button);
  wrapContentItems(button);
};

export const enhancer = (el) => {
  const collapsibleHeadings = el.querySelectorAll("h3");

  map(collapsibleHeadings, (collapsibleHeading, i) => {
    createCollapsibleButton(collapsibleHeading);
  });
};
