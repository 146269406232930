const handleClick = (playerInstance) => (event) => {
  if (playerInstance.videoIsMuted) {
    playerInstance.dispatch("AVP_UNMUTE");
  } else {
    playerInstance.dispatch("AVP_MUTE");
  }
};

const handleUnmute =
  ({ playerInstance, trigger, muteIcon, unmuteIcon }) =>
  () => {
    playerInstance.videoIsMuted = false;
    trigger.setAttribute("aria-label", "Geluid uit");
    trigger.setAttribute("aria-pressed", "true");
    muteIcon.setAttribute("aria-hidden", "true");
    unmuteIcon.setAttribute("aria-hidden", "false");
  };

const handleMute =
  ({ playerInstance, trigger, muteIcon, unmuteIcon }) =>
  () => {
    playerInstance.videoIsMuted = true;
    trigger.setAttribute("aria-label", "Geluid aan");
    trigger.setAttribute("aria-pressed", "false");
    muteIcon.setAttribute("aria-hidden", "false");
    unmuteIcon.setAttribute("aria-hidden", "true");
  };

/**
 * Open volume slider on hover
 */
const handleMouseEnter = (playerInstance) => (event) => {
  playerInstance.dispatch("AVP_OPEN_VOLUME_SLIDER", { type: "volume" });
};

/**
 * Update the volume slider
 */
const handleAdjustVolume =
  ({ playerInstance, trigger, muteIcon, unmuteIcon }) =>
  ({ volume }) => {
    if (volume === 0) {
      handleMute({ playerInstance, trigger, muteIcon, unmuteIcon })();
    } else {
      handleUnmute({ playerInstance, trigger, muteIcon, unmuteIcon })();
    }
  };

/**
 * Play/Pause button
 * @param {HTMLElement} playerInstance
 */
export default (playerInstance) => {
  const trigger = playerInstance.shadowRoot.querySelector(
    ".accessible-video-player__button--mute-button"
  );
  const muteIcon = trigger.querySelector(".js-mute-icon");
  const unmuteIcon = trigger.querySelector(".js-unmute-icon");

  trigger.addEventListener("click", handleClick(playerInstance), false);
  trigger.addEventListener(
    "mouseenter",
    handleMouseEnter(playerInstance),
    false
  );

  playerInstance.observe(
    "AVP_UNMUTE",
    handleUnmute({ playerInstance, trigger, muteIcon, unmuteIcon })
  );
  playerInstance.observe(
    "AVP_MUTE",
    handleMute({ playerInstance, trigger, muteIcon, unmuteIcon })
  );

  // Ensure the button is unmuted when the volume is adjusted.
  playerInstance.observe(
    "AVP_ADJUST_VOLUME",
    handleAdjustVolume({ playerInstance, trigger, muteIcon, unmuteIcon })
  );
};
