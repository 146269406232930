const closeOnEscape = (playerInstance) => (event) => {
  if (event.key !== "Escape") return;

  playerInstance.dispatch("AVP_CLOSE_HOVER_ELEMENT");
  window.removeEventListener("keydown", closeOnEscape);
};

const openTooltip =
  ({ playerInstance, target, itemId }) =>
  ({ id }) => {
    if (id === itemId) {
      target.setAttribute("data-visible", "true");
      window.addEventListener("keydown", closeOnEscape(playerInstance));
    } else {
      // Close when another tooltip opens.
      target.setAttribute("data-visible", "false");
    }
  };

const closeTooltip =
  ({ target }) =>
  () => {
    target.setAttribute("data-visible", "false");
  };

const notifyOpenTooltip = (playerInstance, id) => (event) =>
  playerInstance.dispatch("AVP_OPEN_HOVER_ELEMENT", { id });
const notifyCloseTooltip = (playerInstance) => (event) =>
  playerInstance.dispatch("AVP_CLOSE_HOVER_ELEMENT");

const onLoad =
  ({ onHover = false, onFocus = false }) =>
  (playerInstance, trigger, target, id) => {
    if (onHover) {
      trigger.addEventListener(
        "mouseenter",
        notifyOpenTooltip(playerInstance, id)
      );
      trigger.addEventListener(
        "mouseleave",
        notifyCloseTooltip(playerInstance)
      );
    }

    if (onFocus) {
      trigger.addEventListener("focus", notifyOpenTooltip(playerInstance, id));
      trigger.addEventListener("blur", notifyCloseTooltip);
    }

    // Observers
    playerInstance.observe(
      "AVP_OPEN_HOVER_ELEMENT",
      openTooltip({ playerInstance, target, itemId: id })
    );
    playerInstance.observe("AVP_CLOSE_HOVER_ELEMENT", closeTooltip({ target }));
  };

export const tooltipOnHover = (playerInstance, trigger, target, id) =>
  onLoad({ onHover: true })(playerInstance, trigger, target, id);
export const tooltipOnFocus = (playerInstance, trigger, target, id) =>
  onLoad({ onFocus: true })(playerInstance, trigger, target, id);
export const tooltipOnHoverAndFocus = (playerInstance, trigger, target, id) =>
  onLoad({ onHover: true, onFocus: true })(playerInstance, trigger, target, id);
