import { map } from "./util";

/**
 * Animations don't need a mechanism to stop or pause them,
 * when the animation doesn't last more than 5 seconds.
 * See also: https://www.w3.org/WAI/WCAG21/Understanding/pause-stop-hide.html
 */
const TIMEOUT = 5000;

/**
 * Find CSS animated elements and pause them after x amount of time
 */
const pauseAnimationAfterTimeout = (el) => {
  // Get all the children, including non-direct children
  // https://stackoverflow.com/questions/8321874/how-to-get-all-childnodes-in-js-including-all-the-grandchildren
  const children = el.getElementsByTagName("*");

  map(children, (child) => {
    const style = window.getComputedStyle(child);

    // Search for items with css animations
    const animationName = style.getPropertyValue("animation-name");
    if (animationName === "none") {
      return;
    }

    // Set animation-play-state property to "paused" after set duration has passed
    setTimeout(() => {
      child.style.animationPlayState = "paused";
    }, TIMEOUT);
  });
};

export const enhancer = (el) => {
  pauseAnimationAfterTimeout(el);
};
