import SweetScroll from "sweet-scroll";

/**
 * Scroll when input has focus
 */
export const enhancer = (element) => {
  const input = element.querySelector("input");

  input.addEventListener("focus", (e) => {
    const sweetScroll = new SweetScroll({
      duration: 100,
      easing: "easeInQuad",
    });
    sweetScroll.to("#search");
  });
};
