/**
 * Toggles aria-expanded attributes, while using the classToggler
 */
import { handler as classToggler } from "./class-toggler";

export const handler = (el, e) => {
  classToggler(el, e);

  el.setAttribute(
    "aria-expanded",
    el.getAttribute("aria-expanded") === "false"
  );
};
