import { getScrollPosition } from "./util";

const listeners = {};

const onScroll = (e) => {
  Object.keys(listeners).forEach((key) => {
    if (typeof listeners[key] === "function") {
      listeners[key](e);
    }
  });
};

export const enhancer = () => {
  window.addEventListener("scroll", onScroll);
};

export const register = (key, fn) => {
  listeners[key] = fn;
};

export const unregister = (key) => {
  listeners[key] = undefined;
};

// Can be called with element or with number
export const isScrolledPast = (elm) => {
  if (typeof elm === "number") {
    return getScrollPosition().y >= elm;
  }
  const scrollY = getScrollPosition().y;
  return scrollY + elm.getBoundingClientRect().top <= scrollY;
};

// Not really isVisible, now is it...?
// export const isVisible = (elm, offset = 0) =>
// elm.getBoundingClientRect().top - window.innerHeight <= offset;

export const isVisible = (element, windowHeight) => {
  // Formula stolen with ❤️ from Hakim:
  // https://github.com/hakimel/css/tree/master/progress-nav
  const TOP_MARGIN = 0.1;
  const BOTTOM_MARGIN = 0.2;
  const targetBounds = element.getBoundingClientRect();
  return (
    targetBounds.bottom > windowHeight * TOP_MARGIN &&
    targetBounds.top < windowHeight * (1 - BOTTOM_MARGIN)
  );
};
