import { observe, INDEX_CARDS_WERE_FILTERED } from "./observer";

/**
 * Show / hide a "No results found" container based on matching substances.
 */
export const enhancer = (element) => {
  const noResultContainer = element.querySelector(".js-livesearch-no-result");
  observe(INDEX_CARDS_WERE_FILTERED, ({ results }) => {
    if (results.length === 0) {
      noResultContainer.setAttribute("aria-hidden", "false");
    } else {
      noResultContainer.setAttribute("aria-hidden", "true");
    }
  });
};
