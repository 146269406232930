import _ from "lodash";
import { register as registerScrollListener } from "./scroll-listener";

const SCROLL_LISTENER_ID = "backToTopScrollListener";

let viewportHeight;

const scrollHandler = (el) => (e) => {
  if (window.pageYOffset > viewportHeight) {
    el.classList.remove("is-hidden");
  } else {
    el.classList.add("is-hidden");
  }
};

/**
 * Back to top button
 */
export const enhancer = (el) => {
  viewportHeight = window.innerHeight;
  registerScrollListener(
    SCROLL_LISTENER_ID,
    _.throttle(scrollHandler(el), 200)
  );
};
