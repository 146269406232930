/**
 * Flipper
 */

import { map } from "./util";

export const enhancer = (elm) => {
  const turnButtons = elm.querySelectorAll(".flipper__button");

  map(turnButtons, (turnButton) => {
    turnButton.addEventListener("click", (e) => {
      elm.classList.toggle("is-turned");
    });
  });
};
