import _ from "lodash";

const focusableNodes = `
  a, button, input, select, textarea, svg, area, details, summary,
  iframe, object, embed,
  [tabindex], [contenteditable]
`;

/**
 * Open video in full screen.
 * Source: https://www.w3schools.com/howto/howto_js_fullscreen.asp
 */
const fullScreen =
  ({ videoElement }) =>
  () => {
    if (videoElement.requestFullscreen) {
      videoElement.requestFullscreen();
    } else if (videoElement.webkitRequestFullscreen) {
      // Safari (older versions)
      videoElement.webkitRequestFullscreen();
    } else if (videoElement.webkitEnterFullscreen) {
      // Safari (newer versions)
      videoElement.webkitEnterFullscreen();
    } else if (videoElement.msRequestFullscreen) {
      /* IE11 */
      videoElement.msRequestFullscreen();
    }
  };

/**
 * Update the progress indicator.
 */
const updateProgress =
  ({ videoElement, playerInstance }) =>
  () => {
    playerInstance.dispatch("AVP_UPDATE_PROGRESS_INDICATOR", {
      currentTime: Math.ceil(videoElement.currentTime),
    });
  };

/**
 * Play the video.
 */
const playVideo =
  ({ playerInstance, videoElement }) =>
  () => {
    videoElement.play();

    updateProgress({ videoElement, playerInstance })();
    playerInstance.cachedInterval = setInterval(() => {
      updateProgress({ videoElement, playerInstance })();
    }, 1000);
  };

/**
 * Pause the video.
 */
const pauseVideo =
  ({ playerInstance, videoElement }) =>
  () => {
    videoElement.pause();
    clearInterval(playerInstance.cachedInterval);
  };

/**
 * Mute the video.
 */
const muteVideo =
  ({ videoElement }) =>
  () => {
    videoElement.muted = true;
  };
/**
 * Mute the video.
 */
const unmuteVideo =
  ({ videoElement }) =>
  () => {
    videoElement.muted = false;
  };

/**
 * Adjust the volume.
 */
const AdjustVolume =
  ({ videoElement }) =>
  ({ volume }) => {
    videoElement.volume = volume;
  };

const handleLoadedMetaData =
  ({ videoElement, playerInstance }) =>
  () => {
    playerInstance.dispatch("AVP_LOADED_META_DATA", {
      duration: videoElement.duration,
    });
  };

const handleUpdateVideoProcess =
  ({ videoElement }) =>
  ({ timestamp }) => {
    videoElement.currentTime = timestamp;
  };

const forward =
  ({ videoElement }) =>
  ({ amountOfSeconds }) => {
    videoElement.currentTime += amountOfSeconds;
  };

const rewind =
  ({ videoElement }) =>
  ({ amountOfSeconds }) => {
    videoElement.currentTime -= amountOfSeconds;
  };

/**
 * Disable focusable elements.
 */
const disableFocusableElements =
  ({ playerInstance }) =>
  () => {
    const focusableElements =
      playerInstance.shadowRoot.querySelectorAll(focusableNodes);
    focusableElements.forEach((node) => node.setAttribute("tabindex", "-1"));
  };
/**
 * Enable focusable elements.
 */
const enableFocusableElements =
  ({ playerInstance }) =>
  () => {
    const focusableElements =
      playerInstance.shadowRoot.querySelectorAll(focusableNodes);
    focusableElements.forEach((node) => node.removeAttribute("tabindex"));
  };

/**
 * Update the video source.
 * And play the video.
 * @param {HTMLElement} videoElement
 * @param {string} videoSrc
 */
const updateVideoSource = ({ videoElement, videoSrc }) => {
  if (!videoSrc) return;

  // Remove all source elements.
  videoElement.querySelectorAll("source").forEach((source) => {
    source.remove();
  });

  // Set MP4 source.
  if ("mp4" in videoSrc) {
    const mp4SourceElement = document.createElement("source");
    mp4SourceElement.src = videoSrc.mp4;
    mp4SourceElement.type = "video/mp4";
    videoElement.appendChild(mp4SourceElement);
  }
  // Set WebM source.
  if ("webm" in videoSrc) {
    const webMSourceElement = document.createElement("source");
    webMSourceElement.src = videoSrc.webm;
    webMSourceElement.type = "video/webm";
    videoElement.appendChild(webMSourceElement);
  }

  videoElement.load();
};

/**
 * Get the correct video source based on the width of the video element.
 */
export const getCorrectVideoSource = (videoElement, videoSrc) => {
  const elementWidth = videoElement.offsetWidth;

  if (elementWidth >= 1080) {
    return {
      source: videoSrc["1080"],
      size: 1080,
    };
  }

  if (elementWidth >= 720) {
    return {
      source: videoSrc["720"],
      size: 720,
    };
  }
  if (elementWidth >= 480) {
    return {
      source: videoSrc["480"],
      size: 480,
    };
  }

  return {
    source: videoSrc["320"],
    size: 320,
  };
};

const showVideo = ({
  playerInstance,
  videoElement,
  videoSource,
  changeVideoType,
  autoPlay,
  continuePlaying,
}) => {
  const videoSrc = getCorrectVideoSource(videoElement, videoSource);

  // Bail out, no need to load the same or a smaller video source.
  // Unless the video type has changed.
  if (videoSrc.size <= playerInstance.currentVideoSize && !changeVideoType) {
    console.log("No need to change the video source!");
    return;
  }

  let currentTime = 0;
  if (continuePlaying) {
    // store the current time
    currentTime = videoElement.currentTime;
  }

  // Update currentVideoSize state.
  playerInstance.currentVideoSize = videoSrc.size;
  // Update video source.
  updateVideoSource({ videoElement, videoSrc: videoSrc.source });

  if (continuePlaying && currentTime > 0) {
    playerInstance.dispatch("AVP_UPDATE_VIDEO_PROGRESS", {
      timestamp: currentTime,
    });
  }

  if (autoPlay || (continuePlaying && currentTime > 0)) {
    // Automatically play video when the source is updated.
    playerInstance.dispatch("AVP_PLAY");
  }
};

/**
 * Show the video with audio description.
 */
const showVideoWithAudioDescription =
  ({
    playerInstance,
    videoElement,
    autoPlay = true,
    continuePlaying = false,
  }) =>
  () => {
    const videoTypeIsChanged = playerInstance.videoType !== "audio-description";

    // Update state.
    playerInstance.videoType = "audio-description";

    const audioDescriptionVideoSrc = videoElement.getAttribute(
      "data-audio-description-video-src"
    );
    if (!audioDescriptionVideoSrc) return;

    showVideo({
      playerInstance,
      videoElement,
      videoSource: JSON.parse(audioDescriptionVideoSrc),
      changeVideoType: videoTypeIsChanged,
      autoPlay,
      continuePlaying,
    });
  };
/**
 * Show the default video.
 */
const showDefaultVideo =
  ({
    playerInstance,
    videoElement,
    autoPlay = true,
    continuePlaying = false,
  }) =>
  () => {
    const videoTypeIsChanged = playerInstance.videoType !== "default";

    // Update state.
    playerInstance.videoType = "default";

    const defaultVideoSrc = videoElement.getAttribute("data-default-video-src");
    if (!defaultVideoSrc) return;

    showVideo({
      playerInstance,
      videoElement,
      videoSource: JSON.parse(defaultVideoSrc),
      changeVideoType: videoTypeIsChanged,
      autoPlay,
      continuePlaying,
    });
  };

/**
 * Update the subtitles for the video.
 */
const updateSubtitles =
  ({ videoElement }) =>
  ({ id }) => {
    const subtitleTracks = videoElement.textTracks;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < subtitleTracks.length; i++) {
      const mode = subtitleTracks[i].language === id ? "showing" : "hidden";
      subtitleTracks[i].mode = mode;
    }
  };

/**
 * The video has finished playing.
 */
const handleFinishedVideo = (playerInstance) => {
  playerInstance.dispatch("AVP_VIDEO_HAS_FINISHED");
  clearInterval(playerInstance.cachedInterval);
};

/**
 * The video player.
 *
 * This module is responsible for the video.
 * If the video element should change something (like play-state, volume, subtitles, etc.),
 * it should be done here.
 * But, the handling of the buttons that will execute that action is done in the corresponding module.
 * The communication is done via the observer pattern.
 *
 * @param {HTMLElement} playerInstance, videoElement
 */
export default (playerInstance, videoElement) => {
  // Hide the default controls (just in case they still show up).
  videoElement.controls = false;

  // Add event listeners.
  videoElement.addEventListener(
    "loadedmetadata",
    handleLoadedMetaData({ videoElement, playerInstance })
  );

  // Add event listener for the end of the video.
  videoElement.addEventListener("ended", () =>
    handleFinishedVideo(playerInstance)
  );

  // Update video source on resize.
  const throttledResizeHandler = _.throttle(
    playerInstance.videoType === "audio-description"
      ? showVideoWithAudioDescription({
          playerInstance,
          videoElement,
          autoPlay: false,
          continuePlaying: true,
        })
      : showDefaultVideo({
          playerInstance,
          videoElement,
          autoPlay: false,
          continuePlaying: true,
        }),
    200
  );
  window.addEventListener("resize", throttledResizeHandler);

  // Add a new Source element to the video.
  showDefaultVideo({ playerInstance, videoElement, autoPlay: false })();

  // Add observers.
  playerInstance.observe("AVP_ADJUST_VOLUME", AdjustVolume({ videoElement }));
  playerInstance.observe("AVP_MUTE", muteVideo({ videoElement }));
  playerInstance.observe("AVP_UNMUTE", unmuteVideo({ videoElement }));
  playerInstance.observe(
    "AVP_PLAY",
    playVideo({ playerInstance, videoElement })
  );
  playerInstance.observe(
    "AVP_PAUSE",
    pauseVideo({ playerInstance, videoElement })
  );
  playerInstance.observe("AVP_FORWARD", forward({ videoElement }));
  playerInstance.observe("AVP_REWIND", rewind({ videoElement }));
  playerInstance.observe("AVP_FULL_SCREEN", fullScreen({ videoElement }));
  playerInstance.observe(
    "AVP_UPDATE_SUBTITLES",
    updateSubtitles({ videoElement })
  );
  playerInstance.observe(
    "AVP_UPDATE_VIDEO_PROGRESS",
    handleUpdateVideoProcess({ videoElement })
  );
  playerInstance.observe(
    "AVP_USE_AUDIO_DESCRIPTION_VIDEO",
    showVideoWithAudioDescription({ playerInstance, videoElement })
  );
  playerInstance.observe(
    "AVP_USE_DEFAULT_VIDEO",
    showDefaultVideo({ playerInstance, videoElement })
  );
  playerInstance.observe(
    "AVP_DISABLE_FOCUSABLE_ELEMENTS",
    disableFocusableElements({ playerInstance, videoElement })
  );
  playerInstance.observe(
    "AVP_ENABLE_FOCUSABLE_ELEMENTS",
    enableFocusableElements({ playerInstance, videoElement })
  );
};

export const addVideoToTemplate = (
  template,
  { defaultVideoSrc, audioDescriptionVideoSrc, posterSrc, title }
) => {
  const video = template.querySelector(".accessible-video-player__video");

  // Add the aria-label to the video.
  video.setAttribute("aria-label", title);

  // Add the poster to the video.
  video.poster = posterSrc;

  // Note, the source is set after the video is appended to the shadow root.
  // Otherwise, we don't know the width of the video element and therefore can't determine the correct video source.

  // Add both video sources as data attributes.
  video.setAttribute("data-default-video-src", JSON.stringify(defaultVideoSrc));

  if (!audioDescriptionVideoSrc) {
    const audioDescriptionButton =
      video.parentElement.querySelector("#audio-description").parentElement;
    audioDescriptionButton.setAttribute("aria-hidden", true);

    return template;
  }

  video.setAttribute(
    "data-audio-description-video-src",
    JSON.stringify(audioDescriptionVideoSrc)
  );

  return template;
};
