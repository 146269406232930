/* eslint-disable no-undef */

import { getDataLayer } from "./tracking-datalayer";

const getAttributes = (el) => {
  return {
    type: el.getAttribute("data-event-type"),
    category: el.getAttribute("data-event-cat"),
    action: el.getAttribute("data-event-action"),
    label: el.getAttribute("data-event-label"),
    value: el.getAttribute("data-event-value"),
  };
};

const hasProductionDefined = () => {
  const dataLayer = getDataLayer();
  return (
    Object.keys(dataLayer).length &&
    dataLayer.platformEnvironment === "production"
  );
};

export const trackEvent = (args) => {
  if (!args || !args.type || !args.category || !args.action) {
    console.error("Missing arguments in trackEvent", args);
    return;
  }
  if (typeof _paq === "undefined") {
    return;
  }
  const data = {
    eventType: args.type,
    eventCategory: args.category,
    eventAction: args.action,
    eventLabel: args.label,
    eventValue: args.value,
  };
  if (!hasProductionDefined()) {
    console.log("Tracking event", data);
  }
  _paq.push([
    "trackEvent",
    data.eventCategory,
    data.eventAction,
    data.eventLabel,
  ]);
};

export const handler = (el, e) => {
  trackEvent(getAttributes(el));
};

export const enhancer = (el) => {
  if (el.nodeName.toUpperCase() !== "FORM") {
    console.log("I don't know how to enhance non-forms.");
  }
  el.addEventListener("submit", (e) => {
    trackEvent(getAttributes(el));
  });
};
