import { tooltipOnHoverAndFocus } from "./tooltip";

const handleFullScreenChange = (playerInstance, trigger) => (event) => {
  // The video is going full screen, no need to take action.
  if (document.fullscreenElement) return;

  // When the full-screen mode is closed.
  // Put focus on the initial open button.
  trigger.focus();

  // Remove the event listener.
  window.removeEventListener("fullscreenchange", playerInstance.cachedListener);
  playerInstance.cachedListener = null;
};

/**
 * Open video in full screen.
 */
const handleClick = (playerInstance, trigger) => () => {
  playerInstance.dispatch("AVP_FULL_SCREEN");

  // Move focus back when the full-screen video is closed.
  playerInstance.cachedListener = handleFullScreenChange(
    playerInstance,
    trigger
  );
  window.addEventListener("fullscreenchange", playerInstance.cachedListener);
};

/**
 * Play/Pause button
 * @param {HTMLElement} shadowRoot
 */
export default (playerInstance) => {
  const trigger = playerInstance.shadowRoot.querySelector("#full-screen");

  trigger.addEventListener("click", handleClick(playerInstance, trigger));

  const tooltipElement = trigger.querySelector(
    ".accessible-video-player__button-hover-target"
  );
  tooltipOnHoverAndFocus(
    playerInstance,
    trigger,
    tooltipElement,
    "full-screen"
  );
};
