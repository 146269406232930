import { forEach } from "./util";
import { observe, INDEX_CARDS_WERE_FILTERED } from "./observer";

const activeTpl = (letter, highlight) =>
  `<a
  ${highlight ? 'class="active"' : ""}
  data-handler="trackEventHandler"
  data-hittype="event"
  data-event-type="index-overzicht"
  data-event-cat="letter-zoeken"
  data-event-action="click"
  data-event-label="${letter.toLowerCase()}"
  aria-label="Woorden die beginnen met de letter ${letter}"
  href="#letter-${letter}">${letter}</a>`;

const inactiveTpl = (letter) => `<span>${letter}</span>`;

const toggleVisibility =
  (nodes) =>
  ({ results, query }) => {
    const firstChars = results.reduce((chars, result) => {
      const indexKey = /[a-z]/i.test(result.name[0])
        ? result.name[0].toUpperCase()
        : "#";
      chars[indexKey] = true;

      return chars;
    }, {});

    forEach(nodes, (node) => {
      const letter = node.textContent.trim();
      node.innerHTML =
        letter in firstChars
          ? activeTpl(letter, query.length)
          : inactiveTpl(letter);
    });
  };

export const enhancer = (el) => {
  const nodes = el.getElementsByTagName("li");

  observe(INDEX_CARDS_WERE_FILTERED, toggleVisibility(nodes));
};
