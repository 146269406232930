import _ from "lodash";
import { matchesBreakpoint } from "./responsive";
import {
  isVisible as sectionIsVisible,
  register as registerScrollListener,
  unregister as unregisterScrollListener,
} from "./scroll-listener";

const SCROLL_LISTENER_ITEMS_ID = "tocMenuScrollListenerItems";
const SCROLL_LISTENER_STICKY_ID = "tocMenuScrollListenerSticky";
const BACK_TO_TOP_BUTTON = ".js-toc-menu-back-to-top";

const scrolledPast = (yA, yB) => yA >= yB;

const updateMinHeightPseudoPlaceholder = (n) => {
  if (typeof document.documentElement.style.setProperty === "function") {
    document.documentElement.style.setProperty(
      `--toc-menu-min-height`,
      `${n}px`
    );
  }
};

const onScrollPast = (element, backToTopButton, height) => {
  updateMinHeightPseudoPlaceholder(height);
  element.classList.add("js-is-sticky");
  element.classList.remove("js-is-bottom-aligned");
  backToTopButton.setAttribute("aria-hidden", "false");
  backToTopButton.removeAttribute("tabindex");
};

const onScrollPastBottom = (element, backToTopButton) => {
  element.classList.add("js-is-bottom-aligned");
  element.classList.remove("js-is-sticky");
  backToTopButton.setAttribute("aria-hidden", "true");
  backToTopButton.setAttribute("tabindex", "-1");
};

const onDocumentHeightChange = (element, callback) => {
  let lastHeight = element.clientHeight;
  let newHeight;

  (function run() {
    newHeight = element.clientHeight;
    if (lastHeight !== newHeight) {
      callback();
      lastHeight = newHeight;
    }
    if (element.onElementHeightChangeTimer) {
      clearTimeout(element.onElementHeightChangeTimer);
    }

    element.onElementHeightChangeTimer = setTimeout(run, 200);
  })();
};

const normalize = (element, backToTopButton) => {
  updateMinHeightPseudoPlaceholder(0);
  element.classList.remove("js-is-sticky");
  element.classList.remove("js-is-bottom-aligned");
  backToTopButton.setAttribute("aria-hidden", "true");
  backToTopButton.setAttribute("tabindex", "-1");
};

const elementIsTooHigh = (element, windowHeight) =>
  element.getBoundingClientRect().height > windowHeight;

const setStickyClass = (
  element,
  { tocHeight, topY, bottomY, windowHeight }
) => {
  const backToTopButton = element.querySelector(BACK_TO_TOP_BUTTON);

  if (elementIsTooHigh(element, windowHeight)) {
    normalize(element, backToTopButton);
    return;
  }

  const getOffset = (pageYOffset) =>
    matchesBreakpoint("mediumLarge")
      ? pageYOffset
      : pageYOffset - element.clientHeight;

  const scrollY = getOffset(window.pageYOffset);

  if (bottomY && scrolledPast(scrollY, bottomY)) {
    onScrollPastBottom(element, backToTopButton);
  } else if (scrolledPast(scrollY, topY)) {
    onScrollPast(element, backToTopButton, tocHeight);
  } else {
    normalize(element, backToTopButton);
  }
};

const getBottomBoundary = (element) => {
  const bottomBoundarySelector = element.getAttribute("data-bottom-boundary");
  if (!bottomBoundarySelector) {
    return undefined;
  }
  return (
    document.querySelector(bottomBoundarySelector).getBoundingClientRect().top +
    window.pageYOffset -
    element.getBoundingClientRect().height
  );
};

const getTocMenuItems = (element) => {
  return [].slice
    .call(element.querySelectorAll(".toc-menu__item"))
    .map((li) => {
      const anchor = li.querySelector("a");
      const target = document.getElementById(
        anchor.getAttribute("href").slice(1)
      );
      return { li, anchor, target };
    });
};

const highlightCurrentItem = (items, windowHeight) => {
  const ACTIVE_CLASS = "toc-menu__item--active";

  return items.map((item) =>
    item.li.classList.toggle(
      ACTIVE_CLASS,
      sectionIsVisible(item.target, windowHeight)
    )
  );
};

const createExpandButton = () => {
  const button = document.createElement("button");
  button.innerHTML = '<span class="sr-only">Klap menu uit</span>';
  button.classList.add("toc-menu__toggle-button");
  button.setAttribute("data-handler", "classToggler,trackEventHandler");
  button.setAttribute("data-target", ".toc-menu");
  button.setAttribute("data-target-class", "is-expanded");
  button.setAttribute("data-hittype", "event");
  button.setAttribute("data-event-type", "element-tracking");
  button.setAttribute("data-event-cat", "button");
  button.setAttribute("data-event-action", "click");
  button.setAttribute("data-event-label", "klap-menu-uit-of-in");
  return button;
};

const getScrollListenerForItems = (boundaries, element, menuItems) => () => {
  highlightCurrentItem(menuItems, boundaries.windowHeight);
};

const getScrollListenerForSticky = (boundaries, element, menuItems) => () => {
  setStickyClass(element, boundaries);
};

const getBoundaries = (element) => {
  const tocRect = element.getBoundingClientRect();
  return {
    topY: tocRect.top + window.pageYOffset,
    bottomY: getBottomBoundary(element),
    tocHeight: tocRect.height,
    windowHeight: window.innerHeight,
  };
};

const bindScrollListener = (element, menuItems, backToTopButton) => {
  normalize(element, backToTopButton);
  const boundaries = getBoundaries(element);
  setStickyClass(element, boundaries);

  const scrollListenerForItems = getScrollListenerForItems(
    boundaries,
    element,
    menuItems
  );
  const scrollListenerForSticky = getScrollListenerForSticky(
    boundaries,
    element,
    menuItems
  );
  registerScrollListener(
    SCROLL_LISTENER_ITEMS_ID,
    _.throttle(scrollListenerForItems, 500)
  );
  registerScrollListener(
    SCROLL_LISTENER_STICKY_ID,
    _.throttle(scrollListenerForSticky, 20)
  );
};

const unbindScrollListener = () => {
  unregisterScrollListener(SCROLL_LISTENER_STICKY_ID);
  unregisterScrollListener(SCROLL_LISTENER_ITEMS_ID);
};

const createTocMenu = (element) => {
  const menuItems = getTocMenuItems(element);

  return {
    init() {
      const backToTopButton = element.querySelector(BACK_TO_TOP_BUTTON);

      element.appendChild(createExpandButton());
      bindScrollListener(element, menuItems, backToTopButton);

      window.addEventListener("resize", (e) => {
        unbindScrollListener();
        bindScrollListener(element, menuItems, backToTopButton);
      });

      onDocumentHeightChange(document.body, () => {
        unbindScrollListener();
        bindScrollListener(element, menuItems, backToTopButton);
      });
    },
  };
};

const enhancer = (element) => {
  const toc = createTocMenu(element);
  toc.init();
};

export { enhancer };
