import { map } from "./util";

const siblings = (el) =>
  Array.from(el.parentNode.children).filter((child) => child !== el);

/**
 * Get the focusable elements within a given container.
 */
const getFocusableElements = (container) => {
  return Array.from(container.querySelectorAll("a[href], button"));
};

/**
 * Set or remove the tabindex attribute depending on the visibility.
 */
const toggleTabIndex = (condition, focusableElements) => {
  map(focusableElements, (focusableElement) => {
    if (condition === true) {
      focusableElement.removeAttribute("tabindex");
    } else {
      focusableElement.setAttribute("tabindex", "-1");
    }
  });
};

const togglePanelExpansion = (el) => {
  const panels = [...el.querySelectorAll(".js-featured-categories-panel")];

  map(panels, (panel) => {
    const button = panel.querySelector(".js-featured-categories-button");
    const panelContent = panel.querySelector(
      ".js-featured-categories-panel-content"
    );

    // Set custom property --featured-categories-panel-amount based on panelAmount
    panel.style.setProperty(
      "--featured-categories-panel-amount",
      panels.length
    );

    // Set initial tabindex depending on the visibility.
    toggleTabIndex(
      panel.getAttribute("aria-selected") === "true",
      getFocusableElements(panelContent)
    );

    button.addEventListener("click", (e) => {
      siblings(panel).map((sibling) => {
        const siblingContent = sibling.querySelector(
          ".js-featured-categories-panel-content"
        );
        // Add tabindex attribute on hidden items
        toggleTabIndex(false, getFocusableElements(siblingContent));

        sibling.setAttribute("aria-selected", false);
      });

      panel.setAttribute(
        "aria-selected",
        panel.getAttribute("aria-selected") === "false"
      );

      // Remove tabindex attribute on visible items
      toggleTabIndex(true, getFocusableElements(panelContent));
    });
  });
};

export const enhancer = (el) => {
  togglePanelExpansion(el);
};
