import { debounce, memoize, request } from "./util";

import template from "../lib/micro-template";

const TEMPLATE_ID = "auto-suggest-list";

const KEYUP_TIMEOUT = 300;

const getSuggestion = (query) => {
  const body = {
    size: "5",
    query: {
      multi_match: {
        query,
        fuzziness: "AUTO",
        fields: ["name", "name.autocomplete"],
      },
    },
    _source: "name",
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Basic ${btoa(
      `${window.elasticSearch.username}:${window.elasticSearch.password}`
    )}`,
  };
  return memoize(request)(
    `${window.elasticSearch.url}*/_search`,
    "POST",
    JSON.stringify(body),
    headers
  ).then(JSON.parse);
};

// Use this version to the auto suggest locally
// const getSuggestion = (query) => {
//   return new Promise((resolve, reject) =>
//     resolve({
//       took: 18,
//       timed_out: false,
//       _shards: { total: 30, successful: 30, skipped: 0, failed: 0 },
//       hits: {
//         total: 1,
//         max_score: 14.703339,
//         hits: [
//           {
//             _index: "waarzitwatin_s_product",
//             _type: "waarzitwatin_s_grrr_waarzitwatin_products",
//             _id: "70",
//             _score: 14.703339,
//             _source: { name: "Onkruidbestrijdings&shy;middelen" },
//           },
//           {
//             _index: "waarzitwatin_s_product",
//             _type: "waarzitwatin_s_grrr_waarzitwatin_products",
//             _id: "71",
//             _score: 13.703339,
//             _source: { name: "Een andere stof" },
//           },
//           {
//             _index: "waarzitwatin_s_product",
//             _type: "waarzitwatin_s_grrr_waarzitwatin_products",
//             _id: "72",
//             _score: 12.703339,
//             _source: { name: "Titanium" },
//           },
//         ],
//       },
//     })
//   );
// };

const hideSuggestionsOnClickOutside = (listElm) => {
  document.addEventListener("click", (event) => {
    if (listElm.contains(event.target)) {
      return;
    }
    listElm.innerHTML = "";
  });
};

const hideSuggestionsOnFocusOut = (listElm) => {
  const lastFocusableItem = listElm.querySelector("li:last-child a");
  if (!lastFocusableItem) {
    return;
  }
  lastFocusableItem.addEventListener("focusout", (event) => {
    listElm.innerHTML = "";
  });
};

const renderSuggestions = (listElm, inputElm, id) => (suggestions) => {
  listElm.innerHTML = template(TEMPLATE_ID, { suggestions });
  const listTitle = listElm.querySelector("h3");
  listTitle.setAttribute("id", `auto-suggest-title-${id}`);
  listElm
    .querySelector("ul")
    .setAttribute("aria-labelledby", `auto-suggest-title-${id}`);
  inputElm.setAttribute("aria-expanded", suggestions.length !== 0);

  hideSuggestionsOnFocusOut(listElm);
};

const autoSuggest = (listElm, inputElm, id) => (e) => {
  const query = e.target.value;
  getSuggestion(query)
    .then((data) => {
      return data.hits.hits;
    })
    .then(renderSuggestions(listElm, inputElm, id));
};

export const enhancer = (form) => {
  const inputElm = form.querySelector(".js-auto-suggest-input");
  const listElm = form.querySelector(".js-auto-suggest-list");
  const id = inputElm.getAttribute("id");
  inputElm.addEventListener(
    "keyup",
    debounce(autoSuggest(listElm, inputElm, id), KEYUP_TIMEOUT)
  );
  hideSuggestionsOnClickOutside(listElm);
};
