import { closest } from "./util";

const ConditionFields = (container) => {
  const conditionalFields = container.querySelectorAll([
    "[data-conditional-field]",
  ]);
  const inputContainerClass = container.getAttribute(
    "data-conditional-input-container-class"
  );

  const showField = (input, form) => {
    const inputContainer = closest(input, `.${inputContainerClass}`);
    inputContainer.setAttribute("aria-hidden", false);
    if (input.shouldBeRequired) {
      input.setAttribute("required", true);
    }
  };

  const hideField = (input) => {
    const inputContainer = closest(input, `.${inputContainerClass}`);
    inputContainer.setAttribute("aria-hidden", true);
    input.shouldBeRequired =
      input.shouldBeRequired || input.hasAttribute("required");
    input.removeAttribute("required");
  };

  const toggleField = (input, form) => (e) => {
    const valuesToCheck = input
      .getAttribute("data-conditional-value")
      .split("|");
    return valuesToCheck.indexOf(e.target.value) >= 0
      ? showField(input, form)
      : hideField(input, form);
  };

  const resetFields = () => {
    [...conditionalFields].map((input) => hideField(input));
  };

  const showConditional = (form) => (input) => {
    const inputNameToCheck = input.getAttribute("data-conditional-field");
    const inputsToCheck = form.querySelectorAll(`[name='${inputNameToCheck}']`);
    hideField(input);
    [...inputsToCheck].map((inputToCheck) =>
      inputToCheck.addEventListener("change", toggleField(input, form))
    );
    form.addEventListener("reset", resetFields);
  };

  return {
    init() {
      [...conditionalFields].map(showConditional(container));
    },
  };
};

export const enhancer = (container) => {
  ConditionFields(container).init();
};
