/**
 * Article has been read
 */

export const enhancer = (element) => {
  document.addEventListener("scroll", () => {
    if (element.getBoundingClientRect().top < 0) {
      element.classList.add("is-read");
    } else {
      element.classList.remove("is-read");
    }
  });
};
