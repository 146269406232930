import { handler as classToggler } from "./class-toggler";
import { listenOnce } from "./util";

export const removeVideo = (button, e) => {
  e.preventDefault();

  const videoContainer = button.closest(
    button.getAttribute("data-video-container-target")
  );
  const videoElement = videoContainer.querySelector("accessible-video-player");
  // The videoOverlay is only present on home, so a global selector can be used.
  const videoOverlay = document.querySelector(
    button.getAttribute("data-target")
  );

  videoOverlay.classList.remove("is-visible");
  videoContainer.classList.remove("has-active-video");
  // Stop Video
  videoElement.pauseVideo();

  // Reset Video progress
  videoElement.updateTimestampVideo(0);
};

export const playVideo = (button, e) => {
  e.preventDefault();

  const videoContainerTarget = button.getAttribute(
    "data-video-container-target"
  );
  const videoContainer = button.closest(videoContainerTarget);
  const videoElement = videoContainer.querySelector("accessible-video-player");

  videoContainer.classList.add("has-active-video");

  classToggler(button, e);

  videoElement.enableFocusableElements();
  videoElement.playVideo();

  // If the video is in an overlay, close the video overlay on Escape
  if (button.getAttribute("data-target") === ".js-video-overlay") {
    listenOnce(document, "keydown", (event) => {
      if (event.key === "Escape") {
        removeVideo(button, event);
      }
    });
  }
};

export const disableFocusableVideoElements = (el) => {
  // Disable focusable video elements to prevent them from receiving focus
  // when they are behind the poster image.
  const videoElement = el.querySelector("accessible-video-player");
  videoElement.disableFocusableElements();
};
