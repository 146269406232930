/*
 *  Toggle class for mobile menu
 */
export const handler = (element, e) => {
  const nav = document.querySelector(".site-header__nav");

  element.classList.toggle("js-clicked");
  nav.classList.toggle("js-clicked");

  e.preventDefault();
};
