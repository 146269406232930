const handlePlay =
  ({
    playerInstance,
    trigger,
    triggerScreenReaderLabel,
    playIcon,
    pauseIcon,
  }) =>
  () => {
    trigger.setAttribute("aria-hidden", "true");
    trigger.setAttribute("aria-pressed", "true");
    playIcon.setAttribute("aria-hidden", "true");
    pauseIcon.setAttribute("aria-hidden", "false");
    triggerScreenReaderLabel.textContent = "Pauzeren";
    playerInstance.isPlaying = true;
  };
const handlePause =
  ({
    playerInstance,
    trigger,
    triggerScreenReaderLabel,
    playIcon,
    pauseIcon,
  }) =>
  () => {
    trigger.setAttribute("aria-hidden", "false");
    trigger.setAttribute("aria-pressed", "false");
    playIcon.setAttribute("aria-hidden", "false");
    pauseIcon.setAttribute("aria-hidden", "true");
    triggerScreenReaderLabel.textContent = "Afspelen";
    playerInstance.isPlaying = false;
  };

const handleClick = (playerInstance) => (event) => {
  if (playerInstance.isPlaying) {
    playerInstance.dispatch("AVP_PAUSE");
  } else {
    playerInstance.dispatch("AVP_PLAY");
  }
};

/**
 * Shift focus to the play/pause button
 */
const handleShiftFocus =
  ({ trigger }) =>
  ({ target }) => {
    if (target !== "play/pause") return;
    trigger.focus();
  };

/**
 * Play/Pause button
 * @param {HTMLElement} playerInstance
 */
export default (playerInstance) => {
  const trigger = playerInstance.shadowRoot.querySelector(
    ".accessible-video-player__button--play-pause"
  );
  const triggerScreenReaderLabel = trigger.querySelector(
    ".js-accessible-video-player__button__screen-reader-label"
  );

  const playIcon = trigger.querySelector("[data-icon='play']");
  const pauseIcon = trigger.querySelector("[data-icon='pause']");

  trigger.addEventListener("click", handleClick(playerInstance));

  playerInstance.observe(
    "AVP_PAUSE",
    handlePause({
      playerInstance,
      trigger,
      triggerScreenReaderLabel,
      playIcon,
      pauseIcon,
    })
  );
  playerInstance.observe(
    "AVP_PLAY",
    handlePlay({
      playerInstance,
      trigger,
      triggerScreenReaderLabel,
      playIcon,
      pauseIcon,
    })
  );
  playerInstance.observe(
    "AVP_VIDEO_HAS_FINISHED",
    handlePause({
      playerInstance,
      trigger,
      triggerScreenReaderLabel,
      playIcon,
      pauseIcon,
    })
  );
  playerInstance.observe("AVP_SHIFT_FOCUS", handleShiftFocus({ trigger }));
};
