/* eslint-disable no-use-before-define */

import { filterCards } from "./filter-index-cards";
import {
  dispatch,
  INDEX_CARDS_WERE_FILTERED,
  observe,
  RELEVANT_FILTER_WAS_CHANGED,
} from "./observer";

/**
 * Hide filter list when document is clicked.
 */
const hideListOnClickOutside = (list, expandButton) => (e) => {
  if (expandButton.contains(e.target)) {
    return;
  }
  hideFilterList(list, expandButton);
};

/**
 * Show filter list beneath the filter.
 */
const showFilterList = (list, expandButton) => {
  expandButton.setAttribute("aria-expanded", "true");
  list.classList.add("relevant-filter__select--open");
  document.addEventListener(
    "click",
    hideListOnClickOutside(list, expandButton),
    true
  );
};

/**
 * Hide filter list beneath the filter.
 */
const hideFilterList = (list, expandButton) => {
  expandButton.setAttribute("aria-expanded", "false");
  list.classList.remove("relevant-filter__select--open");
  document.removeEventListener("click", hideListOnClickOutside(list));
};

/**
 * Open / close the options list beneath a filter.
 */
const addClickHandler = (relevantFilterSelect, element) => {
  element.addEventListener("click", (e) => {
    if (element.getAttribute("aria-expanded") === "false") {
      showFilterList(relevantFilterSelect, element);
    } else {
      hideFilterList(relevantFilterSelect, element);
    }
  });
};

/**
 * Hide options list when blurring out of the filter.
 */
const hideFilterListOnBlur = (relevantFilterSelect, element) => {
  relevantFilterSelect.addEventListener("focusout", (e) => {
    if (!relevantFilterSelect.contains(e.relatedTarget)) {
      hideFilterList(relevantFilterSelect, element);
    }
  });
};

/**
 * Enhance the button. Note that the button is not in the same DOM tree as the
 * actual filter.
 */
export const enhancer = (filterContainer) => {
  const dataSource = filterContainer.getAttribute("data-source");
  if (typeof dataSource === "undefined") {
    throw new Error("data-source is a required attribute for livesearch");
  }
  const data = window[dataSource];
  if (typeof data === "undefined") {
    throw new Error(`window[${dataSource}] is undefined.`);
  }

  const button = filterContainer.querySelector(
    ".relevant-filter__trigger-button"
  );
  const relevantFilterSelect = document.querySelector(
    button.getAttribute("data-filter-selector")
  );

  addClickHandler(relevantFilterSelect, button);
  hideFilterListOnBlur(relevantFilterSelect, button);

  const currentFilter = button.querySelector(
    ".js-relevant-filter-current-filter"
  );
  const removeButton = filterContainer.querySelector(
    ".relevant-filter__remove"
  );

  removeButton.addEventListener("click", () => {
    dispatch(RELEVANT_FILTER_WAS_CHANGED);
  });

  observe(RELEVANT_FILTER_WAS_CHANGED, (chosenFilter) => {
    if (chosenFilter) {
      // Update the button's label.
      currentFilter.id = chosenFilter.id;
      currentFilter.innerHTML = chosenFilter.getAttribute("data-filter-name");
      currentFilter.classList.remove("relevant-filter__default-state");
    } else {
      currentFilter.innerHTML = currentFilter.getAttribute("data-label");
      currentFilter.id = currentFilter.getAttribute("data-id");
      currentFilter.classList.add("relevant-filter__default-state");
    }

    // Enable the remove button.
    const showAllId = currentFilter.getAttribute("data-id");
    removeButton.classList.toggle(
      "relevant-filter__remove--active",
      currentFilter.id !== showAllId
    );

    // Close the list.
    relevantFilterSelect.classList.remove("relevant-filter__select--open");
  });

  /**
   * After filtering, respond to the event by disabling any filter option that
   * would yield zero results.
   */
  observe(INDEX_CARDS_WERE_FILTERED, ({ query, categoryId }) => {
    const allOptions = Array.from(
      relevantFilterSelect.querySelectorAll('[data-handler="relevantFilter"]')
    );
    allOptions.forEach((item) => {
      const filterValue = item.getAttribute("data-id");
      const newFilterState = {
        query,
        categoryId,
        riskgroupId: filterValue,
      };

      const wouldGiveNoResults = filterCards(data, newFilterState).length === 0;
      if (wouldGiveNoResults) {
        item.querySelector("button").setAttribute("disabled", true);
      } else {
        item.querySelector("button").removeAttribute("disabled");
      }
    });
  });
};
