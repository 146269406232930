import template from "../lib/micro-template";

const TEMPLATE_ID = "recently-visited-template";
const STORAGE_KEY = "recentlyVisited";
const MAX_ITEMS = 4;

const storeContainsValue = (store, value) => {
  return store.filter((item) => item.title === value.title).length > 0;
};

const getStorage = () => {
  return JSON.parse(sessionStorage.getItem(STORAGE_KEY));
};

const addToStorage = (value) => {
  if (value === undefined) return;
  const store = getStorage() || [];

  if (!store.length || !storeContainsValue(store, value)) store.unshift(value);
  sessionStorage.setItem(
    STORAGE_KEY,
    JSON.stringify(store.slice(0, MAX_ITEMS))
  );
};

export const enhancer = (el) => {
  // Add current page to storage
  addToStorage(window.currentPage);
  // Generate template
  el.innerHTML = template(TEMPLATE_ID, {
    pages: getStorage(),
  });
};
