/**
 * Only show back button when referrer is within site
 */
export const enhancer = (el) => {
  const siteHost = window.location.hostname;

  if (document.referrer.indexOf(siteHost) < 0) {
    el.classList.add("is-hidden");
  }
};
