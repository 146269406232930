import { map } from "./util";

const TIMEOUT = 1400;

/*
 * Quiz answer feedback on buttons
 */
const giveFeedback = (elm) => {
  const correctAnswer = elm.classList.contains("quiz__button--correct");
  elm.classList.toggle("right", correctAnswer);
  elm.classList.toggle("wrong", !correctAnswer);
};

/*
 * Hide answer buttons when answer is given
 */
const hideAnswerButtons = (quizItem) => {
  const lastQuizItem = quizItem.classList.contains("is-last");

  // Don't hide buttons on last slide
  if (!lastQuizItem) {
    const answerButtons = quizItem.querySelectorAll(".quiz__button--answer");
    map(answerButtons, (answerButton) => {
      setTimeout(() => {
        answerButton.classList.add("is-hidden");
      }, TIMEOUT);
    });
  }
};

/*
 * Show next question - button
 */
const showNextButton = (quizItem) => {
  const nextButton = quizItem.querySelector(".quiz__button--next");
  setTimeout(() => {
    nextButton.classList.remove("is-hidden");
  }, TIMEOUT);
};

export const handler = (elm, e) => {
  const quizItem = elm.parentNode.parentNode;
  giveFeedback(elm);
  hideAnswerButtons(quizItem);
  showNextButton(quizItem);
  e.preventDefault();
};
