import { prop, regexFrom, match, map, closest } from "./util";
import { observe, INDEX_CARDS_WERE_FILTERED } from "./observer";

/**
 * Get the focusable elements within the given container.
 */
const getFocusableElements = (container) => {
  const focusableElements = Array.from(
    container.querySelectorAll(
      'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
    )
  );
  return focusableElements;
};

/**
 * Set or remove the tabindex attribute depending on the visibility.
 */
const toggleTabIndex = (shouldBeFocusable, focusableElements) => {
  map(focusableElements, (focusableElement) => {
    if (shouldBeFocusable) {
      focusableElement.removeAttribute("tabindex");
    } else {
      focusableElement.setAttribute("tabindex", "-1");
    }
  });
};

/**
 * Toggle visibility of the card when a search query is performed.
 * The Livesearch enhancer will determine the list of matches: this card
 * toggles its own visibility based on its presence in that list.
 */
const toggleVisibility =
  (el) =>
  ({ results, query }) => {
    const indexCardSlug = el.getAttribute("data-slug");
    const searchResultSlugs = results.map(prop("slug"));
    const cardShouldBeVisible = searchResultSlugs.indexOf(indexCardSlug) !== -1;

    const itemContainer = closest(el, ".js-index-card");

    itemContainer.classList.toggle("is-offscreen", !cardShouldBeVisible);

    // Set or remove the tabindex attribute depending on the visibility.
    toggleTabIndex(cardShouldBeVisible, getFocusableElements(itemContainer));

    const existingSynonym = el.querySelector(".index__synonym");
    if (existingSynonym) {
      existingSynonym.parentNode.removeChild(existingSynonym);
    }

    if (!query) {
      return;
    }

    if (!el.getAttribute("data-secondary-synonyms")) {
      return;
    }

    const synonyms = el.getAttribute("data-secondary-synonyms").split("<|");
    const matchingSynonyms = synonyms.filter(match(regexFrom(query)));
    if (matchingSynonyms.length) {
      el.querySelector(
        "h3"
      ).innerHTML += ` <span class="index__synonym">(${matchingSynonyms[0]})</span>`;
    }
  };

export const enhancer = (el) => {
  observe(INDEX_CARDS_WERE_FILTERED, toggleVisibility(el));
};
