import { tooltipOnHover } from "./tooltip";

const handleKeyDown =
  ({ playerInstance, trigger }) =>
  (event) => {
    if (event.key === "Escape") {
      playerInstance.dispatch("AVP_CLOSE_VOLUME_SLIDER", {
        focusElement: trigger,
      });
    }
  };

const handleMouseLeave = (playerInstance) => {
  playerInstance.dispatch("AVP_CLOSE_VOLUME_SLIDER");
};
const handleBlur = (playerInstance) => {
  playerInstance.dispatch("AVP_CLOSE_VOLUME_SLIDER");
};

const handleInput =
  ({ playerInstance, slider }) =>
  () => {
    playerInstance.volume = parseInt(slider.value, 10);

    playerInstance.dispatch("AVP_ADJUST_VOLUME", {
      volume: playerInstance.volume / 100,
    });
    slider.style.setProperty(
      "--volume-slider-value",
      `${playerInstance.volume}%`
    );
  };

const handleMute =
  ({ slider }) =>
  () => {
    slider.value = 0;
    slider.style.setProperty("--volume-slider-value", `0%`);
  };
const handleUnmute =
  ({ playerInstance, slider }) =>
  () => {
    // Reset volume to the previous value.
    slider.value = playerInstance.volume;
    slider.style.setProperty(
      "--volume-slider-value",
      `${playerInstance.volume}%`
    );
  };

/**
 * Open volume slider when a user focuses on it and it's not yet open.
 */
const handleFocus = (playerInstance) => {
  playerInstance.dispatch("AVP_OPEN_VOLUME_SLIDER", { type: "volume" });
};

/**
 * Close volume slider.
 */
const closeVolumeSlider =
  ({ container }) =>
  ({ focusElement } = {}) => {
    container.setAttribute("data-state", "closed");

    if (focusElement) {
      focusElement.focus();
    }
  };
const openVolumeSlider =
  ({ playerInstance, container, trigger }) =>
  () => {
    container.setAttribute("data-state", "active");

    playerInstance.cachedKeydownEventListener = handleKeyDown({
      playerInstance,
      trigger,
    });
    container.addEventListener(
      "keydown",
      playerInstance.cachedKeydownEventListener
    );
  };

/**
 * Play/Pause button.
 *
 * @param {HTMLElement} playerInstance
 */
export default (playerInstance) => {
  const trigger = playerInstance.shadowRoot.querySelector(
    ".accessible-video-player__button--mute-button"
  );
  const container = playerInstance.shadowRoot.querySelector(
    ".accessible-video-player__volume-container"
  );
  const slider = playerInstance.shadowRoot.querySelector(
    ".accessible-video-player__volume-slider"
  );
  const parent = playerInstance.shadowRoot.querySelector(
    ".accessible-video-player__volume-parent"
  );

  parent.addEventListener("mouseleave", () => {
    handleMouseLeave(playerInstance);
  });

  playerInstance.observe(
    "AVP_CLOSE_VOLUME_SLIDER",
    closeVolumeSlider({
      container,
    })
  );
  playerInstance.observe(
    "AVP_OPEN_VOLUME_SLIDER",
    openVolumeSlider({
      playerInstance,
      trigger,
      container,
    })
  );

  playerInstance.observe(
    "AVP_UNMUTE",
    handleUnmute({ playerInstance, slider })
  );
  playerInstance.observe("AVP_MUTE", handleMute({ slider }));

  slider.addEventListener("focus", () => {
    handleFocus(playerInstance);
  });
  slider.addEventListener("blur", () => {
    handleBlur(playerInstance);
  });
  slider.addEventListener("input", handleInput({ playerInstance, slider }));

  // Set initial volume
  handleInput({ playerInstance, slider })();

  // Open volume-slider on hover.
  // @TODO: Fix this behavior! Could be a difficould one!
  tooltipOnHover(playerInstance, trigger, container, "volume");
};
