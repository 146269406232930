import { filterCards } from "./filter-index-cards";
import {
  CATEGORY_FILTER_WAS_CHANGED,
  dispatch,
  INDEX_CARDS_WERE_FILTERED,
  observe,
} from "./observer";

/* eslint-disable no-use-before-define */
const hideListOnClickOutside = (list, parentElement, expandButton) => (e) => {
  if (parentElement.contains(e.target) || list.contains(e.target)) {
    return;
  }
  hideFilterList(list, parentElement, expandButton);
};

const showFilterList = (list, parentElement, expandButton, className) => {
  if (list.classList.contains(className)) {
    return;
  }
  parentElement.setAttribute("data-is-open", "true");
  expandButton.setAttribute("aria-expanded", "true");
  list.classList.add("category-filter__option-list--open");

  document.addEventListener(
    "focus",
    hideListOnClickOutside(list, parentElement),
    true
  );
  document.addEventListener(
    "click",
    hideListOnClickOutside(list, parentElement),
    true
  );
};

const hideFilterList = (list, parentElement, expandButton) => {
  parentElement.setAttribute("data-is-open", "false");
  expandButton.setAttribute("aria-expanded", "false");
  list.classList.remove("category-filter__option-list--open");
  document.removeEventListener(
    "focus",
    hideListOnClickOutside(list, parentElement, expandButton)
  );
};

export const enhancer = (element) => {
  const dataSource = element.getAttribute("data-source");
  if (typeof dataSource === "undefined") {
    throw new Error("data-source is a required attribute for livesearch");
  }
  const data = window[dataSource];
  if (typeof data === "undefined") {
    throw new Error(`window[${dataSource}] is undefined.`);
  }

  const categoryFilterSelectOpenClass = "category-filter__option-list--open";
  const categoryFilterSelect = document.querySelector(
    ".category-filter__option-list"
  );
  const categoryFilterSearchInput = element.querySelector(
    ".js-category-filter-search"
  );
  const expandButton = element.querySelector(".js-category-filter-button");
  const currentFilter = element.querySelector(
    ".category-filter__current-filter"
  );
  const cancelButton = element.querySelector(".js-category-filter-remove");

  // Attach click listener to reset the chosen option.
  cancelButton.addEventListener("click", () => {
    dispatch(CATEGORY_FILTER_WAS_CHANGED);
  });

  // Attach focus listener to show the list.
  categoryFilterSearchInput.addEventListener("focus", (e) => {
    showFilterList(categoryFilterSelect, element, expandButton);
  });

  // Attach click listener to expand the list.
  expandButton.addEventListener("click", (e) => {
    if (
      !categoryFilterSelect.classList.contains(categoryFilterSelectOpenClass)
    ) {
      categoryFilterSearchInput.focus();
    } else {
      hideFilterList(categoryFilterSelect, element, expandButton);
    }
  });

  // Attach category changed listener.
  observe(CATEGORY_FILTER_WAS_CHANGED, (chosenFilter) => {
    // When a category is chosen, close the list.
    hideFilterList(categoryFilterSelect, element, expandButton);

    if (chosenFilter) {
      // Update the button's label.
      currentFilter.id = chosenFilter.id;
      currentFilter.innerHTML = chosenFilter.getAttribute("data-filter-name");
      currentFilter.parentElement.classList.add(
        "category-filter__text--active"
      );
    } else {
      currentFilter.innerHTML = currentFilter.getAttribute("data-label");
      currentFilter.id = currentFilter.getAttribute("data-id");
      currentFilter.parentElement.classList.remove(
        "category-filter__text--active"
      );
    }

    // Activate the cancel button.
    const showAllId = currentFilter.getAttribute("data-id");
    cancelButton.classList.toggle(
      "category-filter__remove--active",
      currentFilter.id !== showAllId
    );
  });

  /**
   * After filtering, respond to the event by disabling any filter option that
   * would yield zero results.
   */
  observe(INDEX_CARDS_WERE_FILTERED, ({ query, riskgroupId }) => {
    const allOptions = Array.from(
      categoryFilterSelect.querySelectorAll('[data-handler="categoryFilter"]')
    );
    allOptions.forEach((item) => {
      const filterValue = item.getAttribute("data-id");
      const newFilterState = {
        query,
        riskgroupId,
        categoryId: filterValue,
      };

      const wouldGiveNoResults = filterCards(data, newFilterState).length === 0;
      if (wouldGiveNoResults) {
        item.querySelector("button").setAttribute("disabled", true);
      } else {
        item.querySelector("button").removeAttribute("disabled");
      }
    });
  });
};
