const handleKeyDown = (e, playerInstance) => {
  // Bail if the key pressed isn't the space-bar.
  if (e.key !== " ") return;

  e.preventDefault();

  if (playerInstance.isPlaying) {
    playerInstance.dispatch("AVP_PAUSE");
    playerInstance.isPlaying = false;
  } else {
    playerInstance.dispatch("AVP_PLAY");
    playerInstance.isPlaying = true;
  }
};

/**
 * Play/Pause button
 * @param {HTMLElement} playerInstance
 */
export default (playerInstance) => {
  const trigger = playerInstance.shadowRoot.querySelector(
    ".accessible-video-player__initial-play-button-wrapper"
  );

  trigger.addEventListener("keydown", (e) => {
    handleKeyDown(e, playerInstance);
  });
};
