/*
 * Show all ingredients on click
 */
import { map } from "./util";

export const handler = () => {
  const hiddenIngredients = document.querySelectorAll(".ingredients-list");
  map(hiddenIngredients, (hiddenIngredient) => {
    hiddenIngredient.classList.toggle("is-hidden");
    hiddenIngredient.setAttribute(
      "aria-hidden",
      hiddenIngredient.getAttribute("aria-hidden") === "false"
    );
  });
};
