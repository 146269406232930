/* eslint-disable import/no-cycle */

// Import polyfill
import "focus-visible";
import "whatwg-fetch";
import "./polyfills/array-from";
import "./polyfills/object-assign";

import domready from "domready";
import { handle, enhance } from "@grrr/hansel";

// Import functions that are executed on DOMready regardless of DOM
import { enhancer as scrollListener } from "./modules/scroll-listener";
import { enhancer as responsive } from "./modules/responsive";
import disableHoverStylesOnScroll from "./modules/disable-hover-styles-on-scroll";
import loadFontTracking from "./modules/load-font-tracking";
import initializeAccessibleVideoPlayer from "./modules/accessible-video-player/index";

// Import handlers
import { handler as accessibleCollapser } from "./modules/accessible-collapser";
import {
  handler as classToggler,
  toggleOnDocumentClick,
} from "./modules/class-toggler";
import { handler as mobileMenu } from "./modules/mobile-menu";
import { handler as trackEventHandler } from "./modules/track-event";
import { handler as toggleButtonListItems } from "./modules/toggle-button-list-items";
import { handler as toggleIngredientsLists } from "./modules/toggle-ingredients-lists";
import { handler as quiz } from "./modules/quiz";
import { handler as wordDefinition } from "./modules/word-definition";
import { playVideo, removeVideo } from "./modules/video-player";
import { handler as copyToClipboard } from "./modules/copy-to-clipboard";
import { handler as sharerPopup } from "./modules/sharer-popup";
import { handler as ingredientsListHandler } from "./modules/ingredients-list";
import { handler as relevantFilter } from "./modules/relevant-filter";
import { handler as categoryFilter } from "./modules/category-filter";

// Import enhancers
import { enhancer as autoSuggest } from "./modules/auto-suggest";
import { enhancer as backButton } from "./modules/back-button";
import { enhancer as backToTop } from "./modules/back-to-top";
import { enhancer as collapsibleSection } from "./modules/collapsible-section";
import { enhancer as conditionalFields } from "./modules/conditional-fields";
import { disableFocusableVideoElements } from "./modules/video-player";
import { enhancer as feedbackTool } from "./modules/feedback-tool";
import { enhancer as filterIndexCards } from "./modules/filter-index-cards";
import { enhancer as flipper } from "./modules/flipper";
import { enhancer as trackEventEnhancer } from "./modules/track-event";
import { enhancer as readIndicator } from "./modules/read-indicator";
import { enhancer as tocMenu } from "./modules/toc-menu";
import { enhancer as livesearch } from "./modules/livesearch";
import { enhancer as indexCard } from "./modules/index-card";
import { enhancer as indexNav } from "./modules/index-nav";
import { enhancer as sliderEnhancer } from "./modules/slider";
import { enhancer as stickyHeader } from "./modules/sticky-header";
import { enhancer as objectFit } from "./modules/object-fit";
import {
  enhancer as personalisationTool,
  onpopstate as personalisationToolPopState,
} from "./modules/personalisation-tool";
import { enhancer as substanceIndexCard } from "./modules/substance-index-card";
import { enhancer as articleRead } from "./modules/article-read";
import { enhancer as noSearchResults } from "./modules/no-search-results";
import { enhancer as pauseAnimation } from "./modules/pause-animation";
import { enhancer as scrollOnFocus } from "./modules/scroll-on-focus";
import { enhancer as recentlyVisited } from "./modules/recently-visited";
import { enhancer as ingredientsListEnhancer } from "./modules/ingredients-list";
import { enhancer as sanitizeTables } from "./modules/sanitize-tables";
import { enhancer as featuredCategories } from "./modules/featured-categories";
import { enhancer as substanceFilter } from "./modules/substance-filter";
import { enhancer as openCategoryFilter } from "./modules/open-category-filter";
import { enhancer as openRelevantFilter } from "./modules/open-relevant-filter";

// Global callbacks and misc
import { onToggleSearchField } from "./modules/on-toggle-search-field";

import "./polyfills/classList"; // --> Moved to ES6

const executeOnReady = () => {
  disableHoverStylesOnScroll(); // Disable hover styles on scroll
  scrollListener(); // Initialise central scroll listener
  responsive(); // Set document width on resize and orientation change
  loadFontTracking(); // Load fonts.com page view tracking CSS file
  initializeAccessibleVideoPlayer(); // Initialize accessible video player

  // global callbacks
  window.toggleOnDocumentClick = toggleOnDocumentClick;
  window.onToggleSearchField = onToggleSearchField;

  window.addEventListener("popstate", (e) => personalisationToolPopState(e));
};

/**
 * We use handlers and enhancers.
 * For more info:
 * @see https://github.com/grrr-amsterdam/hansel
 *
 * Add handlers and enhancers to the respective lists below, and add the data-handler or
 * data-enhancer attributes to your HTML.
 *
 */
export const HANDLERS = {
  accessibleCollapser,
  classToggler,
  trackEventHandler,
  mobileMenu,
  playVideo,
  toggleButtonListItems,
  toggleIngredientsLists,
  quiz,
  removeVideo,
  wordDefinition,
  copyToClipboard,
  sharerPopup,
  ingredientsListHandler,
  relevantFilter,
  categoryFilter,
};

export const ENHANCERS = {
  autoSuggest,
  trackEventEnhancer,
  backButton,
  backToTop,
  collapsibleSection,
  conditionalFields,
  disableFocusableVideoElements,
  feedbackTool,
  filterIndexCards,
  flipper,
  readIndicator,
  tocMenu,
  livesearch,
  indexNav,
  objectFit,
  personalisationTool,
  sliderEnhancer,
  indexCard,
  noSearchResults,
  pauseAnimation,
  scrollOnFocus,
  substanceIndexCard,
  stickyHeader,
  articleRead,
  recentlyVisited,
  ingredientsListEnhancer,
  sanitizeTables,
  featuredCategories,
  substanceFilter,
  openCategoryFilter,
  openRelevantFilter,
};

const main = () => {
  executeOnReady();
  handle(document.documentElement, HANDLERS);
  enhance(document.documentElement, ENHANCERS);
};

domready(main);
