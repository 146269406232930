/*
 * Show word-definition context content
 */
const toggleVisibility = (elm) => {
  elm.classList.toggle("is-expanded");
};

const toggleAriaAttributes = (elm) => {
  const expandedEl = elm.parentNode.classList.contains("is-expanded");
  const explainableContent = elm.nextElementSibling;

  elm.setAttribute("aria-expanded", "true");
  explainableContent.setAttribute("aria-hidden", "false");

  if (!expandedEl) {
    elm.setAttribute("aria-expanded", "false");
    explainableContent.setAttribute("aria-hidden", "true");
  }
};

export const handler = (elm, e) => {
  toggleVisibility(elm);
  toggleAriaAttributes(elm.querySelector("button"));
};
