const LISTENERS = {};

export const SEARCH_QUERY_WAS_CHANGED = Symbol("SEARCH_QUERY_CHANGED");
export const CATEGORY_FILTER_WAS_CHANGED = Symbol(
  "CATEGORY_FILTER_WAS_CHANGED"
);
export const RELEVANT_FILTER_WAS_CHANGED = Symbol(
  "RELEVANT_FILTER_WAS_CHANGED"
);
export const INDEX_CARDS_WERE_FILTERED = Symbol("INDEX_CARDS_WERE_FILTERED");

export const dispatch = (eventName, payload) => {
  if (typeof LISTENERS[eventName] === "undefined") {
    return;
  }
  LISTENERS[eventName].forEach((fn) => {
    fn(payload);
  });
};

export const observe = (eventName, listener) => {
  if (typeof LISTENERS[eventName] === "undefined") {
    LISTENERS[eventName] = [];
  }
  LISTENERS[eventName].push(listener);
};
